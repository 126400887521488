import React, { useEffect, useRef, useState } from 'react';
import 'ol/ol.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import { OSM } from 'ol/source';
import { fromLonLat, toLonLat } from 'ol/proj';
import GeoJSON from 'ol/format/GeoJSON';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Style, Fill, Stroke, Text } from 'ol/style';
import { click } from 'ol/events/condition';
import Select from 'ol/interaction/Select';
import './test.css';
import Swal from 'sweetalert2';
import axiosInstance from 'utils/axios-instance';

const Test: React.FC = () => {
    const [selectedDong, setSelectedDong] = useState<string | null>(null);
    const mapRef = useRef<HTMLDivElement | null>(null);
    const [map, setMap] = useState<Map | null>(null);
    const selectInteractionRef = useRef<Select | null>(null);

    useEffect(() => {
        if (!mapRef.current) return;

        const initialMap = new Map({
            target: mapRef.current,
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            view: new View({
                center: fromLonLat([127.1, 37.5]),
                zoom: 14,
                minZoom: 1,
                maxZoom: 19,
                constrainResolution: true,
            }),
        });

        setMap(initialMap);

        return () => {
            initialMap.setTarget('');
        };
    }, []);

    useEffect(() => {
        if (!map) return;
        const fetchData = async () => {
            const view = map.getView();
            const zoomLevel = view.getZoom();
            const extent = view.calculateExtent(map.getSize());
            const [minLng, minLat] = toLonLat([extent[0], extent[1]]);
            const [maxLng, maxLat] = toLonLat([extent[2], extent[3]]);

            // 줌 레벨이 14보다 작으면 벡터 레이어를 제거
            if (zoomLevel && zoomLevel < 13) {
                map.getLayers().forEach(layer => {
                    if (layer instanceof VectorLayer) {
                        map.removeLayer(layer);
                    }
                });
                return;
            }

            // 데이터 가져오기
            const response = await axiosInstance.get('/api/geo/features/nearby', {
                params: {
                    minLat,
                    minLng,
                    maxLat,
                    maxLng,
                    zoom: zoomLevel
                }
            });
            const data = response.data;

            const geoJsonFormat = new GeoJSON();
            const features = geoJsonFormat.readFeatures({
                type: "FeatureCollection",
                features: data.contents
            }, {
                featureProjection: 'EPSG:3857',
            });

            // 벡터 소스와 레이어 생성
            const vectorSource = new VectorSource({
                features: features,
            });

            const vectorLayer = new VectorLayer({
                source: vectorSource,
                style: (feature: any) => {
                    const geometry = feature.getGeometry();
                    if (!geometry) return new Style({}); // 기하학적 정보가 없으면 빈 스타일 반환
                    // 좌표 가져오기
                    const coordinates = geometry.getCoordinates();
                    if (!coordinates || !Array.isArray(coordinates[0])) return new Style({}); // 좌표가 유효하지 않으면 빈 스타일 반환

                    // 텍스트 레이블을 위한 중심 좌표 계산
                    let center: [number, number] = [0, 0];
                    if (coordinates[0] && Array.isArray(coordinates[0])) {
                        center = coordinates[0].reduce((acc: [number, number], coord: [number, number]) => {
                            acc[0] += coord[0];
                            acc[1] += coord[1];
                            return acc;
                        }, [0, 0]);
                        center[0] /= coordinates[0].length;
                        center[1] /= coordinates[0].length;
                    }

                    // 텍스트 스타일 설정
                    return new Style({
                        fill: new Fill({
                            color: 'rgba(0, 0, 255, 0.1)',
                        }),
                        stroke: new Stroke({
                            color: '#0000ff',
                            width: 2,
                        }),
                        text: new Text({
                            text: feature.values_.fullNm || 'No Name', // 기본 텍스트 설정
                            font: 'bold 14px Arial', // 폰트 크기 및 굵기 설정
                            fill: new Fill({ color: '#000' }),
                            stroke: new Stroke({
                                color: '#fff',
                                width: 3 // 텍스트 윤곽선의 두께
                            }),
                            offsetX: 0,
                            offsetY: 0,
                            textAlign: 'center',
                            textBaseline: 'middle',
                            placement: 'point', // 텍스트 배치 위치를 포인트로 설정
                        })
                    });
                }
            });

            // 기존 벡터 레이어를 제거하고 새 벡터 레이어를 추가
            map.getLayers().forEach(layer => {
                if (layer instanceof VectorLayer) {
                    map.removeLayer(layer);
                }
            });

            map.addLayer(vectorLayer);

            // 이전 선택 상호작용이 있다면 제거
            if (selectInteractionRef.current) {
                map.removeInteraction(selectInteractionRef.current);
            }

            // 새 선택 상호작용을 생성하여 추가
            const selectInteraction = new Select({
                condition: click,
                style: (feature) => {
                    return new Style({
                        fill: new Fill({
                            color: 'rgba(252, 32, 32, 0.2)',
                        }),
                        text: new Text({
                            text: feature.get('fullNm') || 'No Name', // 텍스트 스타일을 유지
                            font: 'bold 14px Arial',
                            fill: new Fill({ color: '#000' }),
                            stroke: new Stroke({
                                color: '#fff',
                                width: 3
                            }),
                            offsetX: 0,
                            offsetY: 0,
                            textAlign: 'center',
                            textBaseline: 'middle',
                            placement: 'point',
                        }),
                    });
                },
            });

            selectInteraction.on('select', (e) => {
                const feature = e.selected[0];
                if (feature) {
                    const properties = feature.getProperties();
                    Swal.fire({
                        title: properties.fullNm,
                        text: '해당 지역을 선택하시겠습니까?',
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonText: "네",
                        cancelButtonText: "아니요",
                    }).then((result) => {
                        if (result.isConfirmed) {

                        } else {

                        }
                        selectInteraction.getFeatures().clear();
                    });
                    setSelectedDong(properties.fullNm);
                }
            });

            map.addInteraction(selectInteraction);
            selectInteractionRef.current = selectInteraction;
        };

        map.on('moveend', fetchData);

        fetchData();

        return () => {
            // 컴포넌트 언마운트 시 선택 상호작용을 제거
            if (selectInteractionRef.current) {
                map.removeInteraction(selectInteractionRef.current);
            }
        };
    }, [map]);

    return (
        <div className="map-layout">
            <div ref={mapRef} className="map" />
            {selectedDong && <div className="info-box">선택된 지역: {selectedDong}</div>}
        </div>
    );
};

export default Test;
