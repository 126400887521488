import { useContext, useEffect, useState } from 'react';
import './index.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from 'utils/axios-instance';
import MapLayer from 'pages/map_layer';
import { CheckEmptyString } from 'utils/check-empty-string';
import { ConvertDate, ConvertDateTime } from 'utils/dateformatter';
import BoxFrame from 'pages/home/components/box-frame';
import Swal from 'sweetalert2';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from 'context/auth/auth_context';

type BuildingData = {
    item: any;
    point_x: string;
    point_y: string;
    statusCode: number;
};

type Review = {
    id: number;
    createdAt: string;
    createdBy: number;
    deletedAt: string;
    isDeleted: boolean;
    targetId: string;
    replyId: number;
    type: string;
    updated_at: string;
    likeCount: number;
};

type BuildingReviewContent = {
    id: number;
    content: string;
    createdAt: string;
    deletedAt: string;
    isDeleted: boolean;
    rating: number;
    reviewId: number;
    reviewType: string;
    title: string;
    updatedAt: string;
}

type BuildingReviewData = {
    review: Review;
    buildingReviewContent: BuildingReviewContent;
    alreadyLiked: boolean;
}

const BuildingInfo: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [data, setData] = useState<BuildingData | null>(null);
    const [reviewData, setReviewData] = useState<BuildingReviewData[]>([]);
    const [reviewDataLoading, setReviewDataLoading] = useState<boolean>(true);
    const [expandedReviews, setExpandedReviews] = useState<{ [key: number]: boolean }>({});
    const [replyContents, setReplyContents] = useState<{ [key: string]: string }>({});
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!authContext.isLoaded) {
            return;
        }
        setIsAuthenticated(authContext.getIsAuthenticated());
    }
        , [authContext]);

    const toggleReplies = (reviewId: number) => {
        setExpandedReviews(prev => ({ ...prev, [reviewId]: !prev[reviewId] }));
    };

    const handleCommunityButton = async () => {
        try {
            const response = await axiosInstance.get(`/api/community/building/find?uuid=${id}`);
            navigate(`/community/building/${response.data.community.uuid}`);
        } catch { }
    }

    const handleLiked = async (reviewId: number) => {
        if (!isAuthenticated) {
            Swal.fire({
                icon: 'error',
                title: '추천에 실패했습니다.',
                text: '로그인 후 이용해주세요.',
            });
            return;
        }
        try {
            const response = await axiosInstance.post(`/api/review/like`, { reviewId });
            if (response.data.statusCode === 200) {
                if (response.data.type === 1) {
                    Swal.fire({
                        icon: 'success',
                        title: '리뷰 추천에 성공했습니다.',
                    });
                    setReviewData(prevData =>
                        prevData.map(review =>
                            review.review.id === reviewId
                                ? { ...review, alreadyLiked: true, review: { ...review.review, likeCount: review.review.likeCount + 1 } }
                                : review
                        )
                    );
                } else {
                    Swal.fire({
                        icon: 'success',
                        title: '리뷰 추천을 취소했습니다.',
                    });
                    setReviewData(prevData =>
                        prevData.map(review =>
                            review.review.id === reviewId
                                ? { ...review, alreadyLiked: false, review: { ...review.review, likeCount: review.review.likeCount - 1 } }
                                : review
                        )
                    );
                }
            }
        } catch (error) {
            console.error('리뷰 추천 중 오류 발생:', error);
            Swal.fire({
                icon: 'error',
                title: '리뷰 추천 중 오류가 발생했습니다.',
                text: '잠시 후 다시 시도해주세요.',
            });
        }
    };

    const handleReplyChange = (reviewId: number, content: string) => {
        setReplyContents(prev => ({
            ...prev,
            [reviewId]: content
        }));
    };

    const submitReply = async (reviewId: number) => {
        if (!isAuthenticated) {
            Swal.fire({
                icon: 'error',
                title: '댓글 작성에 실패했습니다.',
                text: '로그인 후 이용해주세요.',
            });
            return;
        }
        try {
            const response = await axiosInstance.post(`/api/review/reply`, {
                reviewId,
                content: replyContents[reviewId],
                uuid: id,
            });
            if (response.data.statusCode === 200) {
                Swal.fire({
                    icon: 'success',
                    title: '댓글이 성공적으로 작성되었습니다.',
                });
                setReplyContents(prev => ({
                    ...prev,
                    [reviewId]: ''
                }));
                const fetchReviewData = async () => {
                    try {
                        const response = await axiosInstance.get(`/api/review/building?uuid=${id}`);
                        setReviewData(response.data.buildingReviews);
                    } catch (error) {
                        console.error(error);
                        Swal.fire({
                            icon: 'error',
                            title: '리뷰 데이터를 가져오는 중 오류가 발생했습니다.',
                            text: '잠시 후 다시 시도해주세요.',
                        });
                    } finally {
                        setReviewDataLoading(false);
                    }
                }
                fetchReviewData();
            }
        } catch (error) {
            console.error('댓글 작성 중 오류 발생:', error);
            Swal.fire({
                icon: 'error',
                title: '댓글 작성 중 오류가 발생했습니다.',
                text: '잠시 후 다시 시도해주세요.',
            });
        }
    };

    const renderReviewItem = (review: BuildingReviewData, isReply: boolean = false, parentReview?: BuildingReviewData) => {
        const replies = reviewData.filter(r => r.review.replyId === review.review.id);
        const isAuthor = isReply && parentReview && review.review.createdBy === parentReview.review.createdBy;

        return (
            <div className={`review-item ${isReply ? 'reply' : ''} ${review.review.likeCount >= 10 ? 'gold' : ''
                }`}>
                <div className="review-header">
                    <div className="review-title">
                        <h3>{review.buildingReviewContent.title}</h3>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem',
                        }}>
                            <span>{ConvertDateTime(review.buildingReviewContent.createdAt, ".")}</span>
                            {isAuthor && <span className="author-badge"> 작성자</span>}
                        </div>
                    </div>
                    {!isReply &&
                        <div className="review-rating">
                            <span>{review.buildingReviewContent.rating}점</span>
                        </div>
                    }
                </div>
                <div className="review-content">
                    <p>{review.buildingReviewContent.content}</p>
                </div>
                <div className="review-addition">
                    <div className="review-actions">
                        <button
                            onClick={() => handleLiked(review.review.id)}
                            className={`like-button ${review.alreadyLiked ? 'liked' : ''}`}
                            title={review.alreadyLiked ? '추천 취소' : '추천하기'}
                        >
                            <FontAwesomeIcon icon={faThumbsUp} className="thumb-icon" />
                            <span className="like-count">{review.review.likeCount}</span>
                        </button>
                    </div>
                    {!isReply && replies.length > 0 ? (
                        <div className="reply-toggle" onClick={() => toggleReplies(review.review.id)}>
                            {expandedReviews[review.review.id] ? '댓글 접기' : `댓글 보기 (${replies.length})`}
                        </div>
                    ) : !isReply &&
                    <div className="reply-toggle" onClick={() => toggleReplies(review.review.id)}>
                        댓글 달기
                    </div>
                    }
                </div>
                {!isReply && expandedReviews[review.review.id] && (
                    <div className="replies-container">
                        {replies.map(reply => (
                            <React.Fragment key={reply.review.id}>
                                {renderReviewItem(reply, true, review)}
                            </React.Fragment>
                        ))}
                        {/* 댓글 입력 창 */}
                        <div className={`reply-input-container ${isAuthenticated ? '' : 'disabled'
                            }`}>
                            <textarea
                                placeholder={isAuthenticated ? '댓글을 입력하세요.' : '로그인 후 댓글을 작성할 수 있습니다.'}
                                value={replyContents[review.review.id] || ''}
                                onChange={(e) => handleReplyChange(review.review.id, e.target.value)}
                            />
                            <button
                                onClick={() => submitReply(review.review.id)}
                                disabled={!replyContents[review.review.id]}
                            >등록</button>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(`/api/post-address/search?uuid=${id}`);
                setData(response.data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();

        const fetchReviewData = async () => {
            try {
                const response = await axiosInstance.get(`/api/review/building?uuid=${id}`);
                setReviewData(response.data.buildingReviews);
            } catch (error) {
                console.error(error);
                Swal.fire({
                    icon: 'error',
                    title: '리뷰 데이터를 가져오는 중 오류가 발생했습니다.',
                    text: '잠시 후 다시 시도해주세요.',
                });
            } finally {
                setReviewDataLoading(false);
            }
        }
        fetchReviewData();
    }, [id]);

    return (
        <>
            {data === null &&
                <div className="loading">
                    <div className="loader"></div>
                    <p>게시글을 불러오는 중...</p>
                </div>}
            {data &&
                <div className="building-page">
                    <div>
                        <h1>
                            {
                                CheckEmptyString(data.item.bldNm) === false ? data.item.bldNm : data.item.newPlatPlc
                            }
                        </h1>
                    </div>
                    <div className="divider"></div>
                    <div>
                        <h1>상세 정보</h1>
                        <table>
                            <tbody>
                                <tr>
                                    <td>주차 대수</td>
                                    <td>지상 {data.item.oudrMechUtcnt + data.item.oudrAutoUtcnt}대 / 지하 {data.item.indrMechUtcnt + data.item.indrAutoUtcnt}대</td>
                                </tr>
                                <tr>
                                    <td>세대 수</td>
                                    <td>{data.item.hhldCnt}세대</td>
                                </tr>
                                <tr>
                                    <td>승강기 수</td>
                                    <td>총 {data.item.rideUseElvtCnt}대</td>
                                </tr>
                                <tr>
                                    <td>층 수</td>
                                    <td>지상 {data.item.grndFlrCnt}층 / 지하 {data.item.ugrndFlrCnt}층</td>
                                </tr>
                                <tr>
                                    <td>사용 승인일</td>
                                    <td>{ConvertDate(data.item.useAprDay, ".")}</td>
                                </tr>
                                <tr>
                                    <td>도로명 주소</td>
                                    <td>{data.item.newPlatPlc}</td>
                                </tr>
                                <tr>
                                    <td>지번 주소</td>
                                    <td>{data.item.platPlc}</td>
                                </tr>
                                <tr>
                                    <td>건물 용도</td>
                                    <td>{data.item.mainPurpsCdNm}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {
                        data.item && (
                            <div className="map-container">
                                <h1>위치 정보</h1>
                                <div className="map-wrapper">
                                    <MapLayer
                                        point_x={data.point_x}
                                        point_y={data.point_y}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="box-container">
                        <BoxFrame
                            h2Text="커뮤니티 바로가기"
                            pText="건물에 대해 이야기를 나눠요!"
                            imgSrc="/assets/community.svg"
                            onClick={handleCommunityButton}
                        />
                        <BoxFrame
                            h2Text="동네 리뷰 바로가기"
                            pText="동네에 대한 리뷰를 남겨요!"
                            imgSrc="/assets/review.svg"
                        />
                    </div>
                    <div className="building-review">
                        <h1>거주 후기 ({reviewData.filter(r => r.review.replyId === null).length})</h1>
                        {reviewDataLoading ? (
                            <div className="loader-container">
                                <div className="loader"></div>
                            </div>
                        ) : (
                            <>
                                {reviewData
                                    .filter(review => review.review.replyId === null)
                                    .map((review) => (
                                        <React.Fragment key={review.review.id}>
                                            {renderReviewItem(review)}
                                        </React.Fragment>
                                    ))
                                }
                            </>
                        )}
                        {reviewDataLoading === false && reviewData.length === 0 && (
                            <div className="no-review">
                                작성된 리뷰가 없습니다.
                            </div>
                        )}
                        {/* 더 많은 리뷰 보러가기 -> */}
                        <div className="more-reviews">
                            <button>
                                <Link to={`/review/write/${id}`}>리뷰 작성하기</Link>
                            </button>
                            {reviewDataLoading === false && reviewData.length > 2 && <button>더 많은 리뷰 보러가기</button>}
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default BuildingInfo;