import { useState } from "react";
import Post from "./post";
import "./post_search.css";

interface PostSearchProps {
    getDataFunction: (
        originAddress: string,
        address: string,
        zoneCode: string,
        addressDetail: string,
        sigunguCd: string,
        bjdongCd: string,
        ji: string,
        bun: string
    ) => void;
}

const PostSearch: React.FC<PostSearchProps> = ({
    getDataFunction,
}) => {
    const [enroll_company, setEnroll_company] = useState({
        originAddress: '',
        address: '',
        zoneCode: '',
        sigunguCd: '',
        bjdongCd: '',
        ji: '',
        bun: '',
    });

    const [addressDetail, setAddressDetail] = useState('');

    const [popup, setPopup] = useState(false);

    const handleInput = (e: any) => {
        setEnroll_company({
            ...enroll_company,
            [e.target.name]: e.target.value,
        })
    }

    const handleComplete = (data: any) => {
        setPopup(!popup);
    }

    const handleInputAddressDetail = (e: any) => {
        setAddressDetail(e.target.value);
    }

    const returnData = () => {
        getDataFunction(
            enroll_company.originAddress,
            enroll_company.address,
            enroll_company.zoneCode,
            addressDetail,
            enroll_company.sigunguCd,
            enroll_company.bjdongCd,
            enroll_company.ji,
            enroll_company.bun
        );
    }

    return (
        <div className="address_search" >
            <div className="input-box-container">
                <div
                    className="address-input-1">
                    <input
                        className="address-input-box"
                        placeholder="우편번호"
                        type="text"
                        name="postCode"
                        disabled={true}
                        onChange={handleInput}
                        value={enroll_company.zoneCode} />
                    <button
                        className="address-btn"
                        onClick={handleComplete}>우편번호 찾기</button>
                </div>
                <input
                    className="address-input-box"
                    placeholder="주소"
                    type="text"
                    disabled={true}
                    name="address"
                    onChange={handleInput}
                    value={enroll_company.address} />
                {/* <input
                    className="address-input-box"
                    placeholder="상세 주소 (선택사항)"
                    type="text"
                    name="addressDetail"
                    onChange={handleInputAddressDetail}
                    value={addressDetail}
                /> */}
            </div>
            {popup && <Post company={enroll_company} setcompany={setEnroll_company}></Post>}
            <button
                className="done-btn"
                onClick={returnData}>입력완료</button>
        </div>
    )
}

export default PostSearch;