import React, { useEffect, useState } from "react";
import DaumPostcode from "react-daum-postcode";
import './post.css';
import { CheckEmptyString } from "utils/check-empty-string";

/**
 * 우편번호 검색 모달 커스텀 컴포넌트
 * 기본적으로 DaumPostCode 라이브러리를 사용하여 구현됨.
 * 가져온 정보를 서비스에 맞게 가공하는 소스이니, 주소 관련 작업은 여기서 진행.
 */
const Post = (props: any) => {
    const complete = (data: any) => {
        let originAddress = data.address;
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }
        const bcode = data.bcode;
        const sigunguCd = bcode.substring(0, 5);
        const bjdongCd = bcode.substring(5, 10);

        let jibunAddress = data.jibunAddress;
        if(CheckEmptyString(jibunAddress)) {
            jibunAddress = data.autoJibunAddress;
        }

        const jibun = jibunAddress.split(' ').slice(-1)[0];
        const ji = jibun.split('-')[0];
        const bun = jibun.split('-')[1];

        props.setcompany({
            ...props.company,
            originAddress: originAddress,
            address: fullAddress,
            zoneCode: data.zonecode,
            sigunguCd: sigunguCd,
            bjdongCd: bjdongCd,
            bun: bun,
            ji: ji,
        })
    }

    return (
        <div>
            <DaumPostcode
                className="postmodal"
                autoClose
                onComplete={complete} 
                shorthand={false}
                />
        </div>
    );
};

export default Post;