import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { DefaultHeader } from 'components/header/default_header';
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { DefaultFooter } from 'components/footer/default_footer';
import { routes } from 'routes';
config.autoAddCss = false

function App() {
  return (
    <>
      <DefaultHeader />
      <div className="app-container">
        <div className="content">
          <Routes>
            {routes.map(({ path, element }) => (
              <Route key={path} path={path} element={element} />
            ))}
          </Routes>
        </div>

      </div>
      <DefaultFooter />
    </>
  );
}

export default App;
