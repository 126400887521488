import { useState, useEffect } from "react";
import SearchLayer from "./components/search-layer";
import "./index.css";

const SearchPage = () => {
    const [recentSearches, setRecentSearches] = useState<any[]>([]);

    useEffect(() => {
        const storedSearches = localStorage.getItem('recentSearches');
        if (storedSearches) {
            setRecentSearches(JSON.parse(storedSearches).slice(0, 5));
        }
    }, []);

    const addRecentSearch = (address: string, uuid: string) => {
        const newSearch = { address, uuid };
        const updatedSearches = [newSearch, ...recentSearches.filter(search => search.uuid !== uuid)].slice(0, 5);
        setRecentSearches(updatedSearches);
        localStorage.setItem('recentSearches', JSON.stringify(updatedSearches));
    };

    const removeSearch = (index: number) => {
        const newSearches = recentSearches.filter((_, i) => i !== index);
        setRecentSearches(newSearches);
        localStorage.setItem('recentSearches', JSON.stringify(newSearches));
    };

    return (
        <>
            <div className="recent-searches">
                <h3>최근 검색한 주소</h3>
                {recentSearches.length > 0 ? (
                    <ul className="recent-search-list">
                        {recentSearches.map((search, index) => (
                            <li key={search.uuid} className="recent-search-item">
                                <a href={`/building/${search.uuid}`} className="recent-search-link">
                                    {search.address}
                                </a>
                                <button
                                    className="remove-search-button"
                                    onClick={() => removeSearch(index)}
                                    aria-label="삭제"
                                >
                                    ×
                                </button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="no-recent-searches">최근 검색한 주소가 없습니다.</p>
                )}
            </div>
            <div style={{
                maxWidth: '800px',
                margin: '20px auto',
            }}>
                <SearchLayer onSearch={addRecentSearch} />
            </div>
        </>
    );
};

export default SearchPage;