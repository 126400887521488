import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from 'utils/axios-instance';
import './write.css';
import Swal from 'sweetalert2';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AuthContext } from 'context/auth/auth_context';
import { ImageActions } from '@xeger/quill-image-actions';
import { ImageFormats } from '@xeger/quill-image-formats';
import heic2any from 'heic2any';
import axios from 'axios';
const video = Quill.import('formats/video');

Quill.register('modules/imageActions', ImageActions);
Quill.register('modules/imageFormats', ImageFormats);
Quill.register('formats/video', video);

const MAX_FILE_SIZE = 5 * 1024 * 1024;
const SUPPORTED_IMAGE_TYPES = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/webp',
    'image/heic',
    'image/heif'
];

const isImageFile = (file: File): boolean => {
    return SUPPORTED_IMAGE_TYPES.includes(file.type) ||
        /\.(jpg|jpeg|png|gif|webp|heic|heif)$/i.test(file.name);
};


const BuildingCommunityPostWritePage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const authContext = useContext(AuthContext);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const quillRef = useRef<ReactQuill>(null);

    useEffect(() => {
        if (!authContext.isLoaded) {
            return;
        }
        setIsAuthenticated(authContext.getIsAuthenticated());
        if (authContext.getIsAuthenticated() === false) {
            Swal.fire({
                title: '로그인이 필요합니다.',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: '로그인',
                cancelButtonText: '취소',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/auth/signin', {
                        state: { from: `/community/building/${id}/post` },
                    });
                } else {
                    navigate(`/community/building/${id}/post`);
                }
            });
        }
    }
        , [authContext, id, navigate]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!title.trim() || !content.trim()) {
            Swal.fire({
                icon: 'error',
                title: '입력값 오류',
                text: '제목과 내용을 입력하세요',
            });
            return;
        }

        setIsSubmitting(true);
        try {
            await axiosInstance.post('/api/community/building/post', {
                communityUuid: id,
                title,
                content,
            });
            Swal.fire({
                icon: 'success',
                title: '게시글 작성 성공',
                showConfirmButton: false,
                timer: 1500,
            });
            navigate(`/community/building/${id}/post`);
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: '게시글 작성 실패',
                text: '다시 시도해주세요',
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const imageHandler = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.style.display = 'none';
        document.body.appendChild(input);

        const handleChange = async (event: Event) => {
            try {
                const target = event.target as HTMLInputElement;
                const file = target.files?.[0];
                if (!file) return;

                if (file.size > MAX_FILE_SIZE) {
                    Swal.fire({
                        icon: 'error',
                        title: '파일 크기 초과',
                        text: '파일 크기는 5MB를 초과할 수 없습니다.',
                    });
                    return;
                }

                Swal.fire({
                    title: '이미지 업로드 중...',
                    text: '잠시만 기다려주세요.',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let processedFile = file;
                // HEIC/HEIF 파일 처리
                if (file.type === 'image/heic' || file.type === 'image/heif' || file.name.toLowerCase().endsWith('.heic') || file.name.toLowerCase().endsWith('.heif')) {
                    const blob = await heic2any({
                        blob: file,
                        toType: 'image/jpeg',
                        quality: 0.8
                    });
                    processedFile = new File([blob as Blob], file.name.replace(/\.(heic|heif)$/i, '.jpg'), { type: 'image/jpeg' });
                }

                if (!isImageFile(processedFile)) {
                    throw new Error('지원하지 않는 파일 형식입니다. 이미지 파일만 업로드 가능합니다.');
                }

                const formData = new FormData();
                formData.append('file', processedFile);

                const response = await axiosInstance.post('/api/image/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.status >= 300) {
                    return;
                }

                const imageUrl = response.data.imageUrl;
                const editor = quillRef.current?.getEditor();
                if (editor) {
                    const range = editor.getSelection();
                    if (range) {
                        editor.insertEmbed(range.index, 'image', imageUrl);
                    } else {
                        editor.insertEmbed(0, 'image', imageUrl);
                    }
                }

                Swal.close();
            } catch (error) {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: '이미지 업로드 실패',
                    text: '다시 시도해주세요',
                });
            } finally {
                document.body.removeChild(input);
            }
        };

        input.addEventListener('change', handleChange);
        input.click();
    };

    const videoHandler = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'video/*');
        input.style.display = 'none';
        document.body.appendChild(input);

        const handleChange = async (event: Event) => {
            try {
                const target = event.target as HTMLInputElement;
                const file = target.files?.[0];
                if (!file) return;

                if (file.size > MAX_FILE_SIZE) {
                    Swal.fire({
                        icon: 'error',
                        title: '파일 크기 초과',
                        text: '파일 크기는 5MB를 초과할 수 없습니다.',
                    });
                    return;
                }

                Swal.fire({
                    title: '동영상 업로드 중...',
                    text: '잠시만 기다려주세요.',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                const formData = new FormData();
                formData.append('file', file);

                const response = await axiosInstance.post('/api/image/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.status >= 300) {
                    return;
                }

                const videoUrl = response.data.imageUrl;
                const editor = quillRef.current?.getEditor();
                if (editor) {
                    const range = editor.getSelection();
                    if (range) {
                        editor.insertEmbed(range.index, 'video', videoUrl);
                    } else {
                        editor.insertEmbed(0, 'video', videoUrl);
                    }
                }

                Swal.close();
            } catch (error) {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: '동영상 업로드 실패',
                    text: '다시 시도해주세요',
                });
            } finally {
                document.body.removeChild(input);
            }
        };

        input.addEventListener('change', handleChange);
        input.click();
    };

    const modules = useMemo(() => {
        return {
            imageActions: {},
            imageFormats: {},
            toolbar: {
                container: [
                    ['image', 'video'],
                    [{ header: [1, 2, 3, false] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                ],
                handlers: {
                    image: imageHandler,
                    video: videoHandler,
                },
            },
            
        };
    }, []);

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video',
        'align', 'float', 'width', 'height'
    ];

    return (
        <div className="create-post-page">
            <h1>새 게시글 작성</h1>
            <form onSubmit={handleSubmit} className="create-post-form">
                <div className="form-group">
                    <label htmlFor="title">제목</label>
                    <input
                        type="text"
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="제목을 입력하세요"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="content">내용</label>
                    <ReactQuill
                        ref={quillRef}
                        theme="snow"
                        value={content}
                        onChange={setContent}
                        modules={modules}
                        formats={formats}
                        placeholder="내용을 입력하세요"
                    />
                </div>
                <div className="form-actions">
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? '작성 중...' : '게시글 작성'}
                    </button>
                    <button type="button" onClick={() => navigate(`/community/building/${id}/post`)}>
                        취소
                    </button>
                </div>
            </form>
        </div>
    );
};

export default BuildingCommunityPostWritePage;