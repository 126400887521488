import { Link } from "react-router-dom";
import "./post_list.css";
import { formatCreatedAt } from "utils/dateformatter";
import { faEye, faThumbsUp, faComments } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type CommunityPost = {
    id: number;
    postType: string;
    communityUuid: string;
    title: string;
    content: string;
    createdAt: string;
    createdBy: number;
    likeCount: number;
    viewCount: number;
    replyCount: number;
}

const PostList: React.FC<{ posts: CommunityPost[] }> = ({ posts }) => {
    return (
        <ul className="post-list">
            {posts.length === 0 && <li>게시글이 없습니다.</li>}
            {posts.map((post) => (
                <li key={post.id} className="post-item">
                    <Link to={`/community/building/${post.communityUuid}/post/${post.id}`}>
                        <h3>{post.title}</h3>
                        <div className="post-meta">
                            <div className="post-meta-items">
                                <div>
                                    <FontAwesomeIcon icon={faEye} />
                                    <span> {post.viewCount}</span>
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faThumbsUp} />
                                    <span> {post.likeCount}</span>
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faComments} />
                                    <span> {post.replyCount}</span>
                                </div>
                            </div>
                            <span>{formatCreatedAt(post.createdAt)}</span>
                        </div>
                    </Link>
                </li>
            ))}
        </ul>
    );
};

export default PostList;