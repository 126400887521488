import { useEffect, useState } from 'react';
import './index.css';
import { useParams } from 'react-router-dom';
import axiosInstance from 'utils/axios-instance';

type ReviewData = {
};

const BuildingReivew: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [data, setData] = useState<ReviewData | null>(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(`/api/home/review/${id}`);
                setData(response.data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [id]);
    return (<>{id}</>);
};

export default BuildingReivew;