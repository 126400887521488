import HomePage from 'pages/home';
import SigninPage from 'pages/auth/signin';
import SignupPage from 'pages/auth/signup';
import ReviewPage from 'pages/review';
import SearchPage from 'pages/search';
import PrivacyPolicy from 'pages/terms/privacy-policy';
import UserAgreement from 'pages/terms/user-agreement';
import Test from 'pages/test';
import Maps from 'pages/maps';
import SignOut from 'pages/auth/signout';
import BuildingReivew from 'pages/review/building';
import TownReview from 'pages/review/town';
import BuildingInfo from 'pages/building';
import ReviewWrite from 'pages/review/write';
import BuildingCommunityPage from 'pages/community/building';
import NotFound from 'pages/not_found';
import BuildingPostPage from 'pages/community/building/post';
import BuildingCommunityPostWritePage from 'pages/community/building/post/write';
import BuildingCommunityPostDetailPage from 'pages/community/building/post/detail';
import BuildingCommunityPostEditPage from 'pages/community/building/post/edit';

/**
 * 라우터 정의
 * 1. path: URL 경로
 * 2. title: 페이지 제목(브라우저 탭에 표시)
 * 3. element: 페이지 컴포넌트
 */
export const routes = [
    { path: '/', title: '홈', element: <HomePage /> },
    { path: '/maps', title: '지도', element: <Maps /> },
    { path: '/terms/user-agreement', title: '이용약관', element: <UserAgreement /> },
    { path: '/terms/privacy-policy', title: '개인정보처리방침', element: <PrivacyPolicy /> },
    { path: '/search', title: '검색', element: <SearchPage /> },
    { path: '/review', title: '후기', element: <ReviewPage /> },
    { path: '/auth/signin', title: '로그인', element: <SigninPage /> },
    { path: '/auth/signup', title: '회원가입', element: <SignupPage /> },
    { path: '/auth/signout', title: '로그아웃', element: <SignOut /> },
    { path: '/test', title: 'Test', element: <Test /> },
    { path: '/review/building/:id', title: '건물 후기', element: <BuildingReivew /> },
    { path: '/review/town/:id', title: '동네 후기', element: <TownReview /> },
    { path: '/building/:id', title: '건물 정보', element: <BuildingInfo /> },
    { path: '/town/:id', title: '동네 정보', element: <></> },
    { path: '/review/write/:id', title: '리뷰 작성', element: <ReviewWrite /> },
    { path: '/community/building/:id', title: '건물 커뮤니티', element: <BuildingCommunityPage /> },
    { path: '/community/building/:id/post', title: '게시글', element: <BuildingPostPage /> },
    { path: '/community/building/:id/write', title: '게시글 작성', element: <BuildingCommunityPostWritePage /> },
    { path: '/community/building/:id/post/:postId', title: '게시글 상세', element: <BuildingCommunityPostDetailPage /> },
    { path: '/community/building/:id/post/:postId/edit', title: '게시글 수정', element: <BuildingCommunityPostEditPage /> },
    { path: '*', title: 'Oops! Page Not Found', element: <NotFound /> },
];
