import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const SearchFrame: React.FC = () => {
    return (
        <>
            <Link to="/search">
                <div
                    style={{
                        border: "1px solid #6482AD",
                        borderRadius: "5px",
                        padding: "10px",
                        marginBottom: "10px",
                        position: "relative",
                        cursor: "pointer",
                        display: "flex",
                    }}
                >
                    <span
                        style={{
                            width: "100%",
                            border: "none",
                            outline: "none",
                            fontSize: "15px",
                            fontFamily: "Noto Sans KR",
                            color: "gray",
                        }}>주소를 검색해주세요
                    </span>
                    <FontAwesomeIcon
                        icon={faSearch}
                        style={{
                            position: "absolute",
                            right: "10px",
                            top: "13.5px",
                            color: '#6482AD',
                        }}
                    />
                </div>
            </Link>
        </>
    )
}

export default SearchFrame;