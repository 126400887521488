import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./banner-image.css"
const DefaultSlider: React.FC = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        pauseOnHover: true,
        autoplaySpeed: 2000,
        fade: true,
        dotsClass: "custom-dots"
    };
    const images = [
        "https://placehold.co/4000x4000",
        "https://placehold.co/100X100",
        "https://placehold.co/800x600",
        "https://placehold.co/400x300",
        "https://placehold.co/600x400",
        "https://placehold.co/1600x2000",
    ];

    return (
        <div className="banner-container">
            <Slider {...settings}>
                {images.map((src, index) => (
                    <div className="slide" key={index}>
                        <div className="image-wrapper">
                            <img src={src} alt={`Slide ${index}`} className="banner-image" />
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default DefaultSlider;