
import React from 'react';
import './social_login_button.css';

interface SocialLoginButtonProps {
  platform: string;
  redirect_uri?: string;
}

const SocialLoginButton: React.FC<SocialLoginButtonProps> = ({ platform, redirect_uri }) => {
  const handleClick = async () => {
    const redirectUri = encodeURIComponent(process.env.REACT_APP_ENV === 'localhost' ? 'http://localhost:3000' + redirect_uri : 'https://duriburn.com' + redirect_uri);
    window.location.href = `${process.env.REACT_APP_API_URL}/oauth2/authorize/${platform}?redirect_uri=${redirectUri}`;

  };

  let icon;
  switch (platform) {
    case 'google':
      icon = "/assets/google.png"
      break;
    case 'kakao':
      icon = "/assets/kakao.png"
      break;
    case 'naver':
      icon = "/assets/naver.png"
      break;
    default:
      return null;
  }

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <img
        src={icon}
        alt={`${platform} 로그인`}
        style={{
          width: platform === 'naver' ? '32px' : '18px',
          height: platform === 'naver' ? '32px' : '18px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: platform === 'naver' ? 'translate(-400%, -50%)' : 'translate(-680%, -50%)'
        }}
        className=""
      />
      <button
        className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input hover:text-accent-foreground h-10 px-4 py-2 w-full bg-[#fee500] hover:bg-[#fee800] text-black"
        onClick={handleClick}
        style={{
          backgroundColor: platform ===
            'naver' ? '#03C75A' : platform === 'kakao' ? '#FEE500' : '#FFFFFF',
          color: platform === 'naver' ? 'white' : 'black',
        }}
      >

        <p style={{
          fontFamily: 'Roboto Medium',
          fontWeight: 'bold',
        }}>
          {
            platform === 'naver' ? '네이버' : platform === 'kakao' ? '카카오' : '구글  '
          } 로그인
        </p>
      </button>
    </div>
  );
};

export default SocialLoginButton;
