import { AuthContext } from "context/auth/auth_context";
import { useState, useContext, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axiosInstance from "utils/axios-instance";
import "./index.css";
import PostList from "../components/post_list";
import CommunityJoinModal from "../components/community_join_modal";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

type Community = {
    uuid: string;
    name: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    isEnter: boolean;
    isManager: boolean;
    isOwner: boolean;
    isPassword: boolean;
    userCount: number;
    type: string;
    type2: string;
    geoFeaturesName: string;
    geoFeaturesId: number;
    targetId: string;
}

type CommunityPost = {
    id: number;
    postType: string;
    communityUuid: string;
    title: string;
    content: string;
    createdAt: string;
    createdBy: number;
    likeCount: number;
    viewCount: number;
    replyCount: number;
}

type Response = {
    community: Community;
    statusCode: number;
    recentPosts: CommunityPost[];
    popularPosts: CommunityPost[];
}

const BuildingCommunityPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [community, setCommunity] = useState<Community | null>(null);
    const [recentPosts, setRecentPosts] = useState<CommunityPost[]>([]);
    const [popularPosts, setPopularPosts] = useState<CommunityPost[]>([]);
    const [isJoinModalOpen, setIsJoinModalOpen] = useState(false);
    const [hasPostAccess, setHasPostAccess] = useState<boolean>(true);
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();

    const fetchData = useCallback(async () => {
        try {
            const response: Response = (await axiosInstance.get(`/api/community/building?uuid=${id}`)).data;
            if (response.statusCode === 207) {
                setHasPostAccess(false);
            } else {
                setHasPostAccess(true);
                setRecentPosts(response.recentPosts);
                setPopularPosts(response.popularPosts);
            }
            setCommunity(response.community);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "커뮤니티 정보를 불러오는데 실패했습니다.",
            });
            setHasPostAccess(false);
        }
    }, [id]);

    const enterHandler = async () => {
        if (!isAuthenticated) {
            Swal.fire({
                title: '로그인이 필요합니다.',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: '로그인',
                cancelButtonText: '취소',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/auth/signin', {
                        state: { from: `/community/building/${id}` },
                    });
                }
            } );
            return;
        }
        setIsJoinModalOpen(true);
    };

    const handleModalSubmit = async (nickname: string, password?: string) => {
        try {
            const response = await axiosInstance.post(`/api/community/building/enter`, {
                communityUuid: id,
                nickname: nickname,
                password: password
            });
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "커뮤니티 가입에 성공했습니다.",
            });
            setIsJoinModalOpen(false);
            fetchData();
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status) {
                    if (error.response.status === 400) {
                        Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: "닉네임은 2자 이상 10자 이하이며, 특수문자는 사용할 수 없습니다.",
                        });
                        return;
                    }
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.response.data.message,
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "커뮤니티 가입에 실패했습니다.",
                    });
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "커뮤니티 가입에 실패했습니다.",
                });
            }
        }
    };

    const leaveHandler = async () => {
        try {
            await axiosInstance.post(`/api/community/building/leave`, {
                communityUuid: id
            });
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "커뮤니티 탈퇴에 성공했습니다.",
            });
            // 커뮤니티 정보 다시 불러오기
            fetchData();
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "커뮤니티 탈퇴에 실패했습니다.",
            });
        }
    };

    useEffect(() => {
        if (!authContext.isLoaded) {
            return;
        }
        setIsAuthenticated(authContext.getIsAuthenticated());
    }
        , [authContext]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div className="building-community-page">
            <div className="page-container">
                {community === null ? (
                    <div className="loading">
                        <div className="loader"></div>
                        <p>불러오는 중...</p>
                    </div>
                ) : (
                    <>
                        <CommunityJoinModal
                            isOpen={isJoinModalOpen}
                            isPasswordRequired={community.isPassword}
                            onClose={() => setIsJoinModalOpen(false)}
                            onSubmit={handleModalSubmit}
                        />
                        <div className="community-info">
                            <h1 className="community-name">
                                <Link to={`/building/${community.targetId}`}>{community.name} ({community.geoFeaturesName})</Link>
                            </h1>
                            <p className="community-description">{community.description}</p>
                            <div className="community-meta">
                                멤버 {community.userCount}명 · 생성일: {new Date(community.createdAt).toLocaleDateString()}
                            </div>
                            <div className="button-container">
                                {community.isEnter ? (
                                    <button onClick={leaveHandler} className="leave-button">탈퇴하기</button>
                                ) : (
                                    <button onClick={enterHandler} className="join-button">가입하기</button>
                                )}
                            </div>
                        </div>
                        <div className="content-container">
                            <div className="main-content" style={{ marginBottom: '30px' }}>
                                <div className="content-title">
                                    <h2>바로가기</h2>
                                </div>
                                <div className="quick-content-container">
                                    <div className="quick-content">
                                        <div className="quick-item">
                                            <Link to={`/community/building/${id}/post`}>
                                                <h3>게시판</h3>
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </Link>
                                            <p>커뮤니티의 게시판으로 이동합니다.</p>
                                        </div>
                                        <div className="quick-item">
                                            <Link to={`/community/${id}/settings`}>
                                                <h3>관리자 페이지</h3>
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </Link>
                                            <p>커뮤니티의 관리 설정 페이지로 이동합니다.</p>
                                        </div>
                                    </div>
                                    <div className="contour" />
                                    <div className="quick-content">
                                        <div className="quick-item">
                                            <Link to={`/community/${id}/post`}>
                                                <h3>동네 커뮤니티</h3>
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </Link>
                                            <p>동네 커뮤니티로 이동합니다.</p>
                                        </div>
                                        <div className="quick-item">
                                            <Link to={`/community/${id}/post`}>
                                                <h3>동네 리뷰</h3>
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </Link>
                                            <p>동네 리뷰 페이지로 이동합니다.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-container">
                            <div className="main-content">
                                <div className="content-title">
                                    <h2>최신 게시글</h2>
                                    <Link to={`/community/building/${id}/post`}>
                                        <p>더보기</p>
                                    </Link>
                                </div>
                                {/* 여기에 최신 게시글 목록 */}
                                {hasPostAccess ? (
                                    <PostList posts={recentPosts} />
                                ) : (
                                    <div className="access-denied-message">커뮤니티 가입이 필요합니다.</div>
                                )}
                            </div>
                            <div className="side-content">
                                <div className="content-title">
                                    <h2>인기 게시글</h2>
                                    <Link to={`/community/building/${id}/post`}>
                                        <p>더보기</p>
                                    </Link>
                                </div>
                                {/* 여기에 인기 게시글 목록 */}
                                {hasPostAccess ? (
                                    <PostList posts={popularPosts} />
                                ) : (
                                    <div className="access-denied-message">커뮤니티 가입이 필요합니다.</div>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default BuildingCommunityPage;
