import MapLayer from "pages/map_layer";
import { useLocation } from "react-router-dom";
import "./index.css";
import { ConvertDate } from "utils/dateformatter";
import { CheckEmptyString } from "utils/check-empty-string";

const ReviewPage: React.FC = () => {
    const location = useLocation();
    const state = location.state;

    return (
        <div className="review-page">
            <div>
                <h1>
                    {
                        CheckEmptyString(state.item.bldNm) === false ? state.item.bldNm : state.item.newPlatPlc
                    }
                </h1>
            </div>
            <div className="divider"></div>
            <div>
                <h1>상세 정보</h1>
                <table>
                    <tbody>
                        <tr>
                            <td>주차 대수</td>
                            <td>지상 {state.item.oudrMechUtcnt + state.item.oudrAutoUtcnt}대 / 지하 {state.item.indrMechUtcnt + state.item.indrAutoUtcnt}대</td>
                        </tr>
                        <tr>
                            <td>세대 수</td>
                            <td>{state.item.hhldCnt}세대</td>
                        </tr>
                        <tr>
                            <td>승강기 수</td>
                            <td>총 {state.item.rideUseElvtCnt}대</td>
                        </tr>
                        <tr>
                            <td>층 수</td>
                            <td>지상 {state.item.grndFlrCnt}층 / 지하 {state.item.ugrndFlrCnt}층</td>
                        </tr>
                        <tr>
                            <td>사용 승인일</td>
                            <td>{ConvertDate(state.item.useAprDay, ".")}</td>
                        </tr>
                        <tr>
                            <td>도로명 주소</td>
                            <td>{state.item.newPlatPlc}</td>
                        </tr>
                        <tr>
                            <td>지번 주소</td>
                            <td>{state.item.platPlc}</td>
                        </tr>
                        <tr>
                            <td>건물 용도</td>
                            <td>{state.item.mainPurpsCdNm}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {
                state.item && (
                    <div className="map-container">
                        <h1>위치 정보</h1>
                        <div className="map-wrapper">
                            <MapLayer
                                point_x={state.point_x}
                                point_y={state.point_y}
                            />
                        </div>
                    </div>
                )
            }
            <div>
                <h1>거주 후기 (0)</h1>
            </div>
        </div>
    );
}

export default ReviewPage;