import axios, { AxiosInstance } from 'axios';

const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:8080/api';

const axiosInstance: AxiosInstance = axios.create({
    baseURL: baseURL,
    withCredentials: true,
    timeout: 60000
    // 필요에 따라 다른 기본 설정 추가 가능 (ex. headers)
});

export default axiosInstance;
