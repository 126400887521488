import './box-frame.css';

interface BoxFrameProps {
    h2Text: string;
    pText: string;
    imgSrc?: string;
    onClick?: () => void;
}

const BoxFrame: React.FC<BoxFrameProps> = (props) => {
    return (
        <div className="box-frame"
            onClick={props.onClick}
        >
            <div className="box-frame-text">
                <h2>{props.h2Text}</h2>
                <p>{props.pText}</p>
            </div>
            {
                props.imgSrc &&
                <div className="box-frame-img">
                    <img src={props.imgSrc} alt="box-frame" />
                </div>
            }
        </div>
    )
}

export default BoxFrame;