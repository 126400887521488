const UserAgreement = () => {
    return (
        <div>
            <h1>리뷰홈 개인정보처리방침</h1>
            <h2>1. 개인정보의 처리 목적</h2>
            <p>리뷰홈은 다음 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전 동의를 구할 예정입니다.</p>
            <h3>가. 홈페이지 회원가입 및 관리</h3>
            <p>회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지 목적으로 개인정보를 처리합니다.</p>
            <h3>나. 재화 또는 서비스 제공</h3>
            <p>물품배송, 서비스 제공, 계약서·청구서 발송, 금융거래 본인 인증 및 금융 서비스 제공을 목적으로 개인정보를 처리합니다.</p>
            <h2>2. 개인정보의 처리 및 보유 기간</h2>
            <p>리뷰홈은 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.</p>
            <p>각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</p>
            <h3>가. 홈페이지 회원가입 및 관리</h3>
            <p>리뷰홈은 회원가입 및 관리 목적으로 수집하는 개인정보를 다음과 같이 처리하고 있습니다.</p>
            <ul>
                <li>이름, 아이디, 비밀번호, 이메일, 휴대전화번호: 회원 가입 및 관리</li>
                <li>가입일자: 1년</li>
                <li>최종접속일자: 1년</li>
            </ul>
            <h3>나. 재화 또는 서비스 제공</h3>
            <p>리뷰홈은 재화 또는 서비스 제공 목적으로 수집하는 개인정보를 다음과 같이 처리하고 있습니다.</p>
            <ul>
                <li>이름, 아이디, 비밀번호, 이메일, 휴대전화번호: 회원 가입 및 관리</li>
                <li>가입일자: 1년</li>
                <li>최종접속일자: 1년</li>
            </ul>
            <h2>3. 개인정보의 제3자 제공에 관한 사항</h2>
            <p>리뷰홈은 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.</p>
            <h2>4. 개인정보처리 위탁</h2>
            <p>리뷰홈은 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.</p>
            <ul>
                <li>업체: (주)리뷰홈</li>
                <li>위탁업무 내용: 리뷰홈 서비스 제공</li>
                <li>위탁기간: 1년</li>
            </ul>
            <h2>5. 정보주체의 권리, 의무 및 그 행사방법</h2>
            <p>이용자는 개인정보주체로서 다음과 같은 권리를 행사할 수 있습니다.</p>
            <ul>
                <li>개인정보 열람요구</li>
                <li>오류 등이 있을 경우 정정 요구</li>
                <li>삭제요구</li>
                <li>처리정지 요구</li>
            </ul>
            <h2>6. 개인정보의 파기</h2>
            <p>리뷰홈은 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다.</p>
            <h2>7. 개인정보 보호책임자</h2>
            <p>리뷰홈은 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만 처리 및 피해구제를 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</p>
            <ul>
                <li>성명: 리뷰홈</li>
                <li>직책: 관리자</li>
                <li>연락처: 010-1234-5678</li>
            </ul>
            <p>정보주체께서는 리뷰홈의 서비스를 이용하시며 발생하는 모든 개인정보 보호 관련 문의, 불만 처리, 피해구제 등에 관한 사항을 개인정보 보호책임자에게 문의하실 수 있습니다.</p>
            <h2>8. 개인정보 처리방침 변경</h2>
            <p>이 개인정보 처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.</p>
            <h2>9. 개인정보의 안전성 확보 조치</h2>
            <p>리뷰홈은 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.</p>
            <ul>
                <li>해킹 등에 대비한 기술적 대책</li>
                <li>비인가자에 대한 출입 통제</li>
                <li>비밀번호 암호화</li>
                <li>파일 및 전송 데이터 암호화</li>
            </ul>
        </div>
    );
};

export default UserAgreement;