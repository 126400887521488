import PostSearch from "components/post_search";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axiosInstance from "utils/axios-instance";

interface SearchLayerProps {
    onSearch: (
        address: string,
        uuid: string
    ) => void;
}

const SearchLayer: React.FC<SearchLayerProps> = ({ onSearch }) => {
    const navigate = useNavigate();

    const handleData = async (
        originAddress: string,
        address: string,
        zoneCode: string,
        addressDetail: string,
        sigunguCd: string,
        bjdongCd: string,
        bun: string,
        ji: string,
    ) => {
        //todo : address에서 괄호 제거하고 newPlatPlc로 서버에게 전달.
        if (!sigunguCd || !bjdongCd || !bun) {
            Swal.fire({
                icon: 'error',
                title: '이런...!',
                text: '주소가 입력되지 않았어요! 주소를 입력해주세요.',
            });
            return;
        }
        try {
            Swal.fire({
                title: '검색 중...',
                text: '잠시만 기다려주세요.(최대 1분)',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });
            const result = await axiosInstance.post(
                '/api/post-address/search',
                {
                    sigunguCd: sigunguCd,
                    bjdongCd: bjdongCd,
                    ji: ji,
                    bun: bun,
                    newPlatPlc: originAddress,
                }
            );
            if (result.status === 200 && result.data.uuid) {
                Swal.close();
                onSearch(address, result.data.uuid);
                navigate(`/building/${result.data.uuid}`);
            } else {
                if (result.data.statusCode === 400) {
                    const dongOptions = result.data.dongNm.map((dong: string) => `<option value="${dong.trim()}">${dong.trim()}</option>`).join('');

                    const { value: selectedDong } = await Swal.fire({
                        title: '여러개가 검색되었습니다.',
                        html: `
                          <label for="dong">동을 선택해 주세요</label>
                          <select id="dong" class="swal2-select">
                            ${dongOptions}
                          </select>
                        `,
                        focusConfirm: false,
                        showCancelButton: true,
                        allowOutsideClick: false,
                        confirmButtonText: '검색',
                        preConfirm: () => {
                            const selectedDong = (document.getElementById('dong') as HTMLSelectElement).value;
                            if (!selectedDong) {
                                Swal.showValidationMessage('동을 선택해 주세요.');
                                return false;
                            }
                            return selectedDong;
                        },
                        // 취소버튼
                        cancelButtonText: '취소',
                    });

                    if (selectedDong) {
                        Swal.fire({
                            title: '검색 중...',
                            text: '잠시만 기다려주세요.',
                            allowOutsideClick: false,
                            didOpen: () => {
                                Swal.showLoading();
                            },
                        });
                        // 선택한 동 정보를 포함하여 API 재요청
                        const retryResult = await axiosInstance.post('/api/post-address/search', {
                            sigunguCd: sigunguCd,
                            bjdongCd: bjdongCd,
                            ji: ji,
                            bun: bun,
                            dongNm: selectedDong, // 사용자가 선택한 동
                            newPlatPlc: originAddress,
                        });
                        if (retryResult.status === 200 && retryResult.data.uuid) {
                            Swal.close();
                            onSearch(address + ' ' + selectedDong + '동'
                                , retryResult.data.uuid);
                            navigate(`/building/${retryResult.data.uuid}`);
                        } else {
                            Swal.close();
                            Swal.fire({
                                icon: 'error',
                                title: '검색 실패',
                                text: '재검색 요청이 실패했습니다.',
                            });
                        }
                    }

                } else {
                    throw new Error('검색 결과가 없습니다.');
                }
            }
        } catch (error: any) {
            Swal.close();
            if (error.response && error.response.data) {
                Swal.fire({
                    icon: 'error',
                    title: '검색 실패',
                    text: error.response.data.message,
                });
            }
        }
    };

    return (
        <>
            <PostSearch
                getDataFunction={handleData}
            />
        </>
    );
};

export default SearchLayer;