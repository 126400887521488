import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: 'GmarketSansMedium';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
    font-family: 'GmarketSansMedium', sans-serif;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: 'GmarketSansMedium', sans-serif;
  }

`;
export default GlobalStyle;