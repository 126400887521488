import { Feature, Map as OlMap, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { fromLonLat } from "ol/proj";
import { OSM, Vector, XYZ } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
import { defaults } from "ol/control/defaults";
import { useEffect, useRef } from "react";
import { Point } from "ol/geom";
import Style from "ol/style/Style";
import Icon from "ol/style/Icon";

interface MapLayerProps {
    point_x: string;
    point_y: string;
}

const MapLayer: React.FC<MapLayerProps> = ({
    point_x,
    point_y,
}) => {
    const mapRef = useRef<HTMLDivElement>(null);
    const viewRef = useRef<View | null>(null);

    useEffect(() => {
        const tilelayer = new TileLayer({
            source: new OSM({ attributions: '' })
        });

        const view = new View({
            center: fromLonLat(
                [Number(point_x), Number(point_y)] as [number, number]
            ),
            zoom: 18,
            maxZoom: 18,
            minZoom: 1,
        });
        viewRef.current = view;

        const map = new OlMap({
            controls: defaults({ zoom: false, rotate: false }),
            layers: [
                tilelayer
            ],
            view: view,
        });

        const exisitingLayer = map.getLayers().getArray()[0];
        const source = new XYZ({
            url: `https://api.vworld.kr/req/wmts/1.0.0/${process.env.REACT_APP_vworldapikey}/Satellite/{z}/{y}/{x}.jpeg`,
        });
        const newLayer = new TileLayer({
            source: source
        });
        map.removeLayer(exisitingLayer);
        map.addLayer(newLayer);

        const addTileLayer = new TileLayer({
            visible: true,
            source: new XYZ({
                url: `https://api.vworld.kr/req/wmts/1.0.0/${process.env.REACT_APP_vworldapikey}/Hybrid/{z}/{y}/{x}.png`,
            })
        });
        map.addLayer(addTileLayer);

        const center = map.getView().getCenter();
        if (center) {
            const feature = new Feature(new Point(center));
            const pinLayer = new VectorLayer({
                source: new Vector({
                    features: [feature]
                }),
                style: new Style({
                    image: new Icon({
                        src: '/assets/pin.png',
                        anchor: [0.5, 1]
                    })
                })
            });
            map.addLayer(pinLayer);
        }

        map.setTarget(mapRef.current || '');
        return () => {
            map.setTarget('');
        };
    }, []);

    const moveCenter = () => {
        if (viewRef.current) {
            viewRef.current.animate({
                center: fromLonLat(
                    [Number(point_x), Number(point_y)] as [number, number]
                ),
                duration: 300,
                zoom: 18,
            });
        }
    }

    return (
        <>
            <div ref={mapRef} style={{ width: '100%', height: '200px', position: 'relative', padding: 3 }}>
                <div
                    style={{
                        position: 'absolute',
                        bottom: '10px',
                        right: '10px',
                        zIndex: 1,
                    }}
                >
                    <button
                        style={{
                            padding: '10px',
                            backgroundColor: 'white',
                            border: '1px solid black',
                            borderRadius: '10%',
                            cursor: 'pointer',
                        }}
                        onClick={moveCenter}
                    >
                        <img
                            src="/assets/gps.png"
                            alt="location"
                            style={{
                                width: '20px',
                                height: '20px',
                            }}
                        />
                    </button>
                </div>
            </div>
        </>
    );
}

export default MapLayer;