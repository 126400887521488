import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "context/auth/auth_context";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import "./index.css";
import axiosInstance from "utils/axios-instance";

const ReviewWrite: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();

    const [reviewType, setReviewType] = useState<string>("normal");
    const [rating, setRating] = useState<number>(0);
    const [hoverRating, setHoverRating] = useState<number>(0);
    const [title, setTitle] = useState<string>("");
    const [content, setContent] = useState<string>("");
    const [images, setImages] = useState<File[]>([]);

    useEffect(() => {
        if (!authContext.isLoaded) {
            return;
        }
        if (!authContext.getIsAuthenticated()) {
            Swal.fire({
                icon: 'error',
                title: '로그인이 필요합니다',
                text: '리뷰를 작성하려면 로그인이 필요합니다.',
            }).then(() => {
                navigate('/auth/signin', {
                    state: {
                        from: `/building/${id}`,
                        fromfrom: `/building/${id}`
                    }
                });
            });
        }
    }, [authContext, id, navigate]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const reviewData = {
                targetId: id,
                reviewType,
                content: reviewType !== "simple" ? content : null,
                title: title,
                rating,
            };
            if(rating === 0) {
                Swal.fire("오류", "평점을 선택해주세요.", "error");
                return;
            }
            if(title.trim() === "" ||
                title.trim().length < 5) {
                if(reviewType === "simple") {
                    Swal.fire("오류", "한줄 평가를 입력해주세요.", "error");
                } else {
                    Swal.fire("오류", "제목을 입력해주세요.", "error");
                }
                return;
            }
            if(reviewType !== "simple" && content.trim().length < 10) {
                Swal.fire("오류", "내용을 입력해주세요.", "error");
                return;
            }

            const response = await axiosInstance.post("/api/review/building", reviewData);

            Swal.fire("성공", "리뷰가 성공적으로 등록되었습니다.", "success").then(() => {
                navigate(`/building/${id}`);
            });
        } catch (error) {
            Swal.fire("오류", "리뷰 등록에 실패했습니다.", "error");
        }
    };

    const handleStarHover = (hoveredRating: number) => {
        setHoverRating(hoveredRating);
    };

    const handleStarClick = (clickedRating: number) => {
        setRating(clickedRating);
    };

    return (
        <div className="review-container">
            <h1>리뷰 작성</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>리뷰 타입</label>
                    <select value={reviewType} onChange={(e) => setReviewType(e.target.value)}>
                        <option value="simple">간단 리뷰</option>
                        <option value="normal">일반 리뷰</option>
                        <option value="detailed">상세 리뷰</option>
                    </select>
                </div>

                <div className="form-group">
                    <label>평점</label>
                    <div className="star-rating">
                        {[1, 2, 3, 4, 5].map((star) => (
                            <span
                                key={star}
                                className={`star ${star <= (hoverRating || rating) ? 'filled' : ''}`}
                                onMouseEnter={() => handleStarHover(star)}
                                onMouseLeave={() => handleStarHover(0)}
                                onClick={() => handleStarClick(star)}
                            >
                                ★
                            </span>
                        ))}
                    </div>
                </div>

                <div className="form-group">
                    <label>{reviewType === "simple" ? "한줄 평가" : "제목"} (5자 이상)</label>
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required={reviewType === "normal"}
                    />
                </div>

                {reviewType !== "simple" && (
                    <div className="form-group">
                        <label>상세 내용 (10자 이상)</label>
                        <textarea
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            required
                        />
                    </div>
                )}

                {reviewType !== "simple" && (
                    <div className="form-group">
                        <label>사진 (선택)</label>
                        <input
                            type="file"
                            multiple
                            accept="image/*"
                            onChange={(e) => setImages(Array.from(e.target.files || []))}
                            disabled
                        />
                        <small>이미지 업로드 기능은 현재 비활성화되어 있습니다.</small>
                    </div>
                )}

                <button type="submit" className="submit-button">리뷰 등록</button>
            </form>
        </div>
    );
};

export default ReviewWrite;