import React, { createContext, useState, useContext, ReactNode } from 'react';

interface HistoryContextType {
    history: string[];
    pushHistory: (path: string) => void;
    popHistory: () => string | undefined;
}

const HistoryContext = createContext<HistoryContextType | undefined>(undefined);

interface HistoryProviderProps {
    children: ReactNode;
}

export const HistoryProvider: React.FC<HistoryProviderProps> = ({ children }) => {
    const [history, setHistory] = useState<string[]>([]);

    const pushHistory = (path: string) => {
        setHistory(prev => [...prev, path]);
    };

    const popHistory = (): string | undefined => {
        if (history.length > 1) {
            const newHistory = history.slice(0, -1);
            setHistory(newHistory);
            return newHistory[newHistory.length - 1];
        }
        return undefined;
    };

    return (
        <HistoryContext.Provider value={{ history, pushHistory, popHistory }}>
            {children}
        </HistoryContext.Provider>
    );
};

export const useHistory = (): HistoryContextType => {
    const context = useContext(HistoryContext);
    if (context === undefined) {
        throw new Error('useHistory must be used within a HistoryProvider');
    }
    return context;
};