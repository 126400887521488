import React, { useState } from 'react';
import './community_join_modal.css';

interface CommunityJoinModalProps {
    isOpen: boolean;
    isPasswordRequired: boolean;
    onClose: () => void;
    onSubmit: (nickname: string, password?: string) => void;
}

const CommunityJoinModal: React.FC<CommunityJoinModalProps> = ({
    isOpen,
    isPasswordRequired,
    onClose,
    onSubmit
}) => {
    const [nickname, setNickname] = useState('');
    const [password, setPassword] = useState('');

    if (!isOpen) return null;

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (nickname.trim()) {
            onSubmit(nickname, isPasswordRequired ? password : undefined);
            setNickname('');
            setPassword('');
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>커뮤니티 가입</h2>
                <p>* 닉네임은 해당 커뮤니티에서만 적용됩니다.</p>
                <hr />
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        value={nickname}
                        onChange={(e) => setNickname(e.target.value)}
                        placeholder="닉네임을 입력해주세요"
                        required
                    />
                    {isPasswordRequired && (
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="비밀번호를 입력해주세요"
                            required
                        />
                    )}
                    <div className="modal-buttons">
                        <button type="submit">확인</button>
                        <button type="button" onClick={onClose}>취소</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CommunityJoinModal;