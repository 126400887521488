import React, { useEffect } from 'react';
import './error_modal.css';

interface ErrorModalProps {
    open: boolean;
    onClose: () => void;
    message: String;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ open, onClose, message }) => {
    if (!open) return null;
    // useEffect(() => {
    //     console.log('ErrorModal rendered');
    // }, []);
    return (
        <div className="fixed top-4 z-50 w-full max-w-md -translate-x-1/2 rounded-lg bg-red-500 p-4 text-white shadow-lg slide-in">
            <div className="flex items-center justify-between">
                <div>
                    <h3 className="text-lg font-bold">Error</h3>
                    <p className="text-sm">{message}</p>
                </div>
                <button
                    onClick={onClose}
                    className="rounded-md bg-red-600 px-3 py-2 text-sm font-medium hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
                    Close
                </button>
            </div>
        </div>
    );
}

export default ErrorModal;