import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from '../src/context/auth/auth_context';
import { CookiesProvider } from 'react-cookie';
import GlobalStyle from 'GlobalStyles';
import { HistoryProvider } from 'context/history/history_context';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <CookiesProvider>
    <BrowserRouter>
      <HistoryProvider>
        <AuthProvider>
          <App />
          <GlobalStyle />
        </AuthProvider>
      </HistoryProvider>
    </BrowserRouter>
  </CookiesProvider>
);
