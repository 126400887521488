import { AuthContext } from "context/auth/auth_context";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SignOut: React.FC = () => {
    const authContext = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    const prevPath = location.state?.from || '/';

    useEffect(() => {
        authContext.signOut();
        navigate(prevPath);
    }, [authContext, navigate, prevPath]);

    return null;
}

export default SignOut;