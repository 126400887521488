import React, { useEffect, useRef, useState } from 'react';
import 'ol/ol.css';
import { Map, Overlay, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import { OSM } from 'ol/source';
import { fromLonLat, toLonLat } from 'ol/proj';
import GeoJSON from 'ol/format/GeoJSON';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Style, Fill, Stroke, Text } from 'ol/style';
import { click } from 'ol/events/condition';
import Select from 'ol/interaction/Select';
import './maps.css';
import Swal from 'sweetalert2';
import { useSearchParams } from 'react-router-dom';
import axiosInstance from 'utils/axios-instance';

const Maps: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedDong = useRef<string | null>(null);
    const mapRef = useRef<HTMLDivElement | null>(null);
    const overlayRef = useRef<HTMLDivElement | null>(null);  // 팻말을 위한 ref
    const [popupContent, setPopupContent] = useState<JSX.Element | null>(null);
    const [map, setMap] = useState<Map | null>(null);
    const [overlay, setOverlay] = useState<Overlay | null>(null);
    const selectInteractionRef = useRef<Select | null>(null);
    const shouldKeepLayout = useRef<boolean>(true);
    const [globalFeatures, setGlobalFeatures] = useState<any>(null);
    const type = searchParams.get('type');
    const loadingDiv = useRef<HTMLDivElement | null>(null);


    // 맵 초기화 및 팻말 초기화
    useEffect(() => {
        if (!mapRef.current) return;

        const initialMap = new Map({
            target: mapRef.current,
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            view: new View({
                center: fromLonLat([127.1, 37.5]),
                zoom: 14,
                minZoom: 1,
                maxZoom: 19,
                constrainResolution: true,
            }),
        });

        const newOverlay = new Overlay({
            element: overlayRef.current!,
            positioning: 'bottom-center',
            stopEvent: true,
            offset: [0, -10],  // 팻말이 약간 위로 위치하도록 조정
        });

        initialMap.addOverlay(newOverlay);
        setOverlay(newOverlay);
        setMap(initialMap);

        return () => {
            initialMap.setTarget('');
        };
    }, []);

    /**
     * 데이터를 가져오는 비동기 함수
     * 1. 서버로부터 데이터를 가져옴.
     * 2. 해당 데이터를 globalFeatures에 저장
     */
    const fetchingData = async () => {
        if (!map) return;
        const view = map.getView();
        const zoomLevel = view.getZoom();
        const extent = view.calculateExtent(map.getSize());
        const [minLng, minLat] = toLonLat([extent[0], extent[1]]);
        const [maxLng, maxLat] = toLonLat([extent[2], extent[3]]);

        // 줌 레벨이 14보다 작으면 벡터 레이어를 제거
        if (zoomLevel && zoomLevel < 13) {
            shouldKeepLayout.current = false;
            selectedDong.current = null;
            clearMap();
            return;
        }
        // 줌 레벨이 정수가 아닐 경우 정수로 치환
        let zoomLevelInt = Math.round(zoomLevel ? zoomLevel : 14);

        // 데이터 가져오기
        try {
            if(loadingDiv.current)
                loadingDiv.current.style.display = 'block';
            const response = await axiosInstance.get(`/api/geo/features/nearby`, {
                params: {
                    minLat,
                    minLng,
                    maxLat,
                    maxLng,
                    zoom: zoomLevelInt
                }
            });
            const data = response.data;
            const geoJsonFormat = new GeoJSON();
            const features = geoJsonFormat.readFeatures({
                type: "FeatureCollection",
                features: data.contents
            }, {
                featureProjection: 'EPSG:3857',
            });

            setGlobalFeatures(features);
        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: 'error',
                title: '데이터를 불러오는 중 오류가 발생했습니다.',
                text: '잠시 후 다시 시도해주세요.',
            });
        } finally {
            if(loadingDiv.current)
                loadingDiv.current.style.display = 'none';
        }
    };

    /**
     * feature데이터가 갱신될 때 로직
     *  1. feature 데이터가 갱신되면 해당 데이터를 이용하여 벡터 레이어를 생성
     *  2. 해당 벡터 레이어를 맵에 추가
     *  3. 선택 상호작용을 추가하여 해당 feature를 클릭했을 때 팻말을 띄움
     */
    useEffect(() => {
        if (!map) return;
        const vectorSource = new VectorSource({
            features: globalFeatures,
        });

        const vectorLayer = new VectorLayer({
            source: vectorSource,
            style: (feature: any) => {
                const geometry = feature.getGeometry();
                if (!geometry) return new Style({}); // 기하학적 정보가 없으면 빈 스타일 반환
                // 좌표 가져오기
                const coordinates = geometry.getCoordinates();
                if (!coordinates || !Array.isArray(coordinates[0])) return new Style({}); // 좌표가 유효하지 않으면 빈 스타일 반환

                // 텍스트 레이블을 위한 중심 좌표 계산
                let center: [number, number] = [0, 0];
                if (coordinates[0] && Array.isArray(coordinates[0])) {
                    center = coordinates[0].reduce((acc: [number, number], coord: [number, number]) => {
                        acc[0] += coord[0];
                        acc[1] += coord[1];
                        return acc;
                    }, [0, 0]);
                    center[0] /= coordinates[0].length;
                    center[1] /= coordinates[0].length;
                }
                // 텍스트 스타일 설정
                return new Style({
                    fill: selectedDong.current === feature.values_.fullNm ? new Fill({
                        color: 'rgba(252, 32, 32, 0.2)',
                    }) : new Fill({
                        color: 'rgba(0, 0, 255, 0.1)',
                    }),
                    stroke: new Stroke({
                        color: '#0000ff',
                        width: 2,
                    }),
                    text: new Text({
                        text: feature.values_.fullNm || 'No Name', // 기본 텍스트 설정
                        font: 'bold 14px Arial', // 폰트 크기 및 굵기 설정
                        fill: new Fill({ color: '#000' }),
                        stroke: new Stroke({
                            color: '#fff',
                            width: 3 // 텍스트 윤곽선의 두께
                        }),
                        offsetX: 0,
                        offsetY: 0,
                        textAlign: 'center',
                        textBaseline: 'middle',
                        placement: 'point', // 텍스트 배치 위치를 포인트로 설정
                    })
                });
            }
        });

        // 기존 벡터 레이어를 제거하고 새 벡터 레이어를 추가
        map.getLayers().forEach(layer => {
            if (layer instanceof VectorLayer) {
                map.removeLayer(layer);
            }
        });
        map.addLayer(vectorLayer);

        // 새 선택 상호작용을 생성하여 추가
        const selectInteraction = new Select({
            condition: click,
            style: (feature) => {
                return new Style({
                    fill: new Fill({
                        color: 'rgba(255, 32, 32, 0.2)',
                    }),
                    text: new Text({
                        text: feature.get('fullNm') || 'No Name', // 텍스트 스타일을 유지
                        font: 'bold 14px Arial',
                        fill: new Fill({ color: '#000' }),
                        stroke: new Stroke({
                            color: '#fff',
                            width: 3
                        }),
                        offsetX: 0,
                        offsetY: 0,
                        textAlign: 'center',
                        textBaseline: 'middle',
                        placement: 'point',
                    }),
                });
            },
        });

        selectInteraction.on('select', (e) => {
            const feature = e.selected[0];
            if (feature && overlay && overlayRef.current) {
                const properties = feature.getProperties();
                selectedDong.current = properties.fullNm;
                setPopupContent(
                    <div>
                        <h3>{properties.fullNm}</h3>
                        <button onClick={() => {
                            Swal.fire({
                                title: properties.fullNm,
                                text: "자세한 정보를 확인하시겠습니까?",
                                confirmButtonText: '확인',
                            });
                            selectedDong.current = 'null';
                            shouldKeepLayout.current = false;
                            if (overlay && overlayRef.current) {
                                overlay.setPosition(undefined);
                                overlayRef.current.classList.remove('visible');
                            }
                            // 선택한 영억 색깔 다시 변경
                            map.getLayers().forEach(layer => {
                                if (layer instanceof VectorLayer) {
                                    map.removeLayer(layer);
                                }
                            });

                            fetchingData();
                        }}>자세히 보기</button>
                    </div>
                );

                const geometry: any = feature.getGeometry();
                const coordinates = geometry.getExtent();
                const center = [
                    (coordinates[0] + coordinates[2]) / 2,
                    (coordinates[1] + coordinates[3]) / 2,
                ];

                shouldKeepLayout.current = true;
                if (overlay && overlayRef.current) {
                    overlay.setPosition(undefined);
                    overlayRef.current.classList.remove('visible');
                }
                map.getView().animate(
                    {
                        center: center,
                        duration: 100, // 애니메이션 지속 시간 (밀리초)
                    }, () => {
                        // 애니메이션 완료 후에 overlay의 위치를 설정
                        overlay.setPosition(center);
                        overlayRef.current?.classList.add('visible');
                    }
                );
            } else if (overlay && overlayRef.current) {
                overlay.setPosition(undefined);
                overlayRef.current.classList.remove('visible');
                setPopupContent(null);
            }
        });

        map.addInteraction(selectInteraction);
        selectInteractionRef.current = selectInteraction;

        if (overlay && overlayRef.current && !shouldKeepLayout.current) {
            overlay.setPosition(undefined);
            overlayRef.current.classList.remove('visible');
        }
    }, [globalFeatures]);

    /**
     * 맵이 변경될 때 로직 (맵이 변경되는 경우 : 줌인, 줌아웃, 드래그 등)
     * 1. shouldKeepLayout 값에 따라 팻말을 유지할지 결정
     * 2. 서버로부터 데이터를 가져와서 벡터 레이어를 생성
     */
    useEffect(() => {
        if (!map) return;

        // Fetch initial data
        // fetchingData();

        // Define a callback for 'moveend' event
        const handleMoveEnd = () => {
            fetchingData();
        };

        // Add event listener
        map.on('moveend', handleMoveEnd);

        // Clean up event listener on unmount or when `map` changes
        return () => {
            map.un('moveend', handleMoveEnd);
        };
    }, [map]);

    useEffect(() => {
        // 맵 및 상호작용 설정 로직
        return () => {
            if (map) {
                if (selectInteractionRef.current) {
                    map.removeInteraction(selectInteractionRef.current);
                }
                if (overlay && overlayRef.current) {
                    overlay.setPosition(undefined);
                    overlayRef.current.classList.remove('visible');
                }
                map.getLayers().forEach(layer => {
                    if (layer instanceof VectorLayer) {
                        map.removeLayer(layer);
                    }
                });
            }
        };
    }, [map, overlay]);

    /**
     * 선택된 동이 변경될 때 로직
     */
    // useEffect(() => {
    //     if (!map) return;
    //     if (overlay && overlayRef.current) {
    //         overlay.setPosition(undefined);
    //         overlayRef.current.classList.remove('visible');
    //     }
    //     // 벡터 소스와 레이어 생성
    //     const vectorSource = new VectorSource({
    //         features: globalFeatures,
    //     });

    //     const vectorLayer = new VectorLayer({
    //         source: vectorSource,
    //         style: (feature: any) => {
    //             const geometry = feature.getGeometry();
    //             if (!geometry) return new Style({}); // 기하학적 정보가 없으면 빈 스타일 반환
    //             // 좌표 가져오기
    //             const coordinates = geometry.getCoordinates();
    //             if (!coordinates || !Array.isArray(coordinates[0])) return new Style({}); // 좌표가 유효하지 않으면 빈 스타일 반환

    //             // 텍스트 레이블을 위한 중심 좌표 계산
    //             let center: [number, number] = [0, 0];
    //             if (coordinates[0] && Array.isArray(coordinates[0])) {
    //                 center = coordinates[0].reduce((acc: [number, number], coord: [number, number]) => {
    //                     acc[0] += coord[0];
    //                     acc[1] += coord[1];
    //                     return acc;
    //                 }, [0, 0]);
    //                 center[0] /= coordinates[0].length;
    //                 center[1] /= coordinates[0].length;
    //             }
    //             // 텍스트 스타일 설정
    //             return new Style({
    //                 fill: selectedDong === feature.values_.fullNm ? new Fill({
    //                     color: 'rgba(252, 32, 32, 0.2)',
    //                 }) : new Fill({
    //                     color: 'rgba(0, 0, 255, 0.1)',
    //                 }),
    //                 stroke: new Stroke({
    //                     color: '#0000ff',
    //                     width: 2,
    //                 }),
    //                 text: new Text({
    //                     text: feature.values_.fullNm || 'No Name', // 기본 텍스트 설정
    //                     font: 'bold 14px Arial', // 폰트 크기 및 굵기 설정
    //                     fill: new Fill({ color: '#000' }),
    //                     stroke: new Stroke({
    //                         color: '#fff',
    //                         width: 3 // 텍스트 윤곽선의 두께
    //                     }),
    //                     offsetX: 0,
    //                     offsetY: 0,
    //                     textAlign: 'center',
    //                     textBaseline: 'middle',
    //                     placement: 'point', // 텍스트 배치 위치를 포인트로 설정
    //                 })
    //             });
    //         }
    //     });

    //     // 기존 벡터 레이어를 제거하고 새 벡터 레이어를 추가
    //     map.getLayers().forEach(layer => {
    //         if (layer instanceof VectorLayer) {
    //             map.removeLayer(layer);
    //         }
    //     });

    //     map.addLayer(vectorLayer);
    // }, [selectedDong])

    const clearMap = () => {
        if (!map) return;
    
        // Remove interactions
        if (selectInteractionRef.current) {
            map.removeInteraction(selectInteractionRef.current);
            selectInteractionRef.current = null;
        }
    
        // Remove overlays
        if (overlay && overlayRef.current) {
            overlay.setPosition(undefined);
            overlayRef.current.classList.remove('visible');
        }
    
        // Remove layers
        map.getLayers().forEach(layer => {
            if (layer instanceof VectorLayer) {
                map.removeLayer(layer);
            }
        });
    };

    return (
        <div className="map-layout">
            <div ref={mapRef} className="map" />
            <div ref={loadingDiv} className="loading-overlay"> 데이터 로딩중... </div>
            <div ref={overlayRef} className="popup">
                {popupContent}
            </div>
        </div>
    );
};

export default Maps;
