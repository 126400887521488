import { Link, matchPath, useLocation, useNavigate } from "react-router-dom";
import './default_header.css';
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "context/auth/auth_context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBars as bars, faArrowRightFromBracket, faUser } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft as chevronLeft } from "@fortawesome/free-solid-svg-icons";
import DefaultSideBar from "components/sidebar/default_sidebar";
import DefaultDropdown from "components/dropdown/default_dropdown";
import { routes } from "routes";
import { useHistory } from "context/history/history_context";

export const DefaultHeader: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { pushHistory } = useHistory();
    const authContext = useContext(AuthContext);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isSideBarOpen, setSideBarOpen] = useState<boolean>(false);
    const [scrolled, setScrolled] = useState<boolean>(false);
    const [view, setView] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        const title = process.env.REACT_APP_TITLE;
        const route = routes.find(r => matchPath(r.path, location.pathname));
        let subTitle = route?.title;
        document.title = `${subTitle} | ${title}`;
    }, [location]);

    const checkIsMobile = () => {
        setIsMobile(window.innerWidth <= 767);
    }

    const getLinkClass = (path: string) => {
        return location.pathname === path ? 'active-link' : 'inactive-link';
    }

    useEffect(() => {
        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            setScrolled(scrollTop > 50);  // 스크롤이 50px 이상일 때 색상 변경
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('resize', checkIsMobile);
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    useEffect(() => {
        if (!authContext.isLoaded) {
            return;
        }
        setIsAuthenticated(authContext.getIsAuthenticated());
    }, [authContext, location]);

    const showBackButton = location.pathname !== '/';

    const toggleSideBar = () => {
        setSideBarOpen(prev => !prev);
    }

    return (
        <header className={`header ${scrolled ? 'scrolled' : ''}`}>
            {(showBackButton && isMobile) && (
                <div><button className="back-button" onClick={() => navigate(-1)}>
                    <FontAwesomeIcon
                        icon={chevronLeft as IconProp}
                        size="2x"
                        color="rgb(100, 130, 173)"
                    />
                </button></div>
            )}
            {
                isMobile ?
                    <Link className="logo" to="/" style={{ cursor: 'pointer' }}>
                        <img className="logo-image"
                            src="/logo512.png"
                            alt="logo"
                        />
                        <p>두리번</p>
                    </Link>
                    :
                    <Link className="logo" to="/" style={{ cursor: 'pointer' }}>
                        <img className="logo-image"
                            src="/assets/logo_v2.png"
                            alt="logo"
                        />
                    </Link>
            }
            {!isMobile ?
                <>
                    <div className="menu-container">
                        <Link to="/" className={getLinkClass('/')}>
                            홈
                        </Link>
                        <Link to="/search" className={getLinkClass('/search')}>
                            건물 검색
                        </Link>
                        <Link to="/maps" className={getLinkClass('/maps')}>
                            동네 지도
                        </Link>
                        <Link to="/community" className={getLinkClass('/community')}>
                            커뮤니티
                        </Link>
                    </div>
                    {
                        isAuthenticated ?
                            <DefaultDropdown
                                displayName={`${authContext.userInfo.nickname} 님`}
                                view={view}
                                setView={setView}
                                items={[
                                    { name: "내 정보", favicon: faUser, faviconPosition: 'left', onClick: () => navigate('/mypage') },
                                    {
                                        name: "로그아웃", favicon: faArrowRightFromBracket, faviconPosition: 'left'
                                        , color: "red", onClick: () => navigate('/auth/signout', {
                                            state: { from: location.pathname }
                                        })
                                    },
                                ]}
                            />
                            :
                            <div className="button-container">
                                <Link to="/auth/signin" style={{ textDecoration: 'none' }} state={
                                    { from: location.pathname }
                                }>
                                    <button className="header-button">
                                        로그인
                                    </button>
                                </Link>
                            </div>
                    }
                </>
                :
                <>
                    <div className="button-container">
                        <button className="header-button-m" onClick={toggleSideBar}>
                            <FontAwesomeIcon
                                icon={bars as IconProp}
                                size="2x"
                                color="rgb(100, 130, 173)"
                            />
                        </button>
                    </div>
                    <DefaultSideBar
                        width={250}
                        isOpen={isSideBarOpen}
                        onOpen={() => setSideBarOpen(true)}
                        onClose={() => setSideBarOpen(false)}
                        children={
                            <div className="sidebar-menu-container">
                                <div className="sidebar-menu" onClick={
                                    () => {
                                        setSideBarOpen(false);
                                    }
                                }>
                                    <Link to="/" className={getLinkClass('/')}>
                                        홈
                                    </Link>
                                    <Link to="/search" className={getLinkClass('/search')}>
                                        건물 검색
                                    </Link>
                                    <Link to="/maps" className={getLinkClass('/maps')}>
                                        동네 지도
                                    </Link>
                                    <Link to="/community" className={getLinkClass('/community')}>
                                        커뮤니티
                                    </Link>
                                </div>
                                <hr></hr>
                                {
                                    isAuthenticated ?
                                        <DefaultDropdown
                                            displayName={`${authContext.userInfo.nickname} 님`}
                                            view={view}
                                            setView={setView}
                                            items={[
                                                { name: "내 정보", favicon: faUser, faviconPosition: 'left', onClick: () => navigate('/mypage') },
                                                {
                                                    name: "로그아웃", favicon: faArrowRightFromBracket, faviconPosition: 'left'
                                                    , color: "red", onClick: () => navigate('/auth/signout', 
                                                        { state: { from: location.pathname } }
                                                    )
                                                },
                                            ]}
                                        />
                                        :
                                        <div className="button-container">
                                            <Link to="/auth/signin" style={{ textDecoration: 'none' }} state={
                                                { from: location.pathname }
                                            }>
                                                <button className="header-button"
                                                    onClick={() => setSideBarOpen(false)}
                                                >
                                                    로그인
                                                </button>
                                            </Link>
                                        </div>
                                }
                            </div>
                        }
                    />
                </>
            }
        </header>
    );
}