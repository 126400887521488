import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import './index.css'; // CSS 파일을 import 합니다.
import axiosInstance from 'utils/axios-instance';
import { formatCreatedAt } from 'utils/dateformatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faComments, faEye, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from 'context/auth/auth_context';
import Swal from 'sweetalert2';
import { debounce } from 'lodash';

type CommunityPost = {
    id: number;
    postType: string;
    communityUuid: string;
    title: string;
    content: string;
    createdAt: string;
    createdBy: number;
    likeCount: number;
    viewCount: number;
    replyCount: number;
}

type Pagination = {
    page: number;
    size: number;
    totalElements: number;
    totalPages: number;
    last: boolean;
}

const BuildingPostPage = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [posts, setPosts] = useState<CommunityPost[]>([]);
    const [pagination, setPagination] = useState<Pagination>({
        page: 0,
        size: 12,
        totalElements: 0,
        totalPages: 0,
        last: false,
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [communityName, setCommunityName] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const authContext = useContext(AuthContext);

    useEffect(() => {
        if (!authContext.isLoaded) {
            return;
        }
        setIsAuthenticated(authContext.getIsAuthenticated());
    }
        , [authContext]);

    useEffect(() => {
        fetchPosts();
    }, [id, pagination.page]);

    const fetchPosts = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`/api/community/building/posts`, {
                params: {
                    uuid: id,
                    page: pagination.page,
                    size: pagination.size,
                    search: searchTerm,
                },
            });
            setPosts(response.data.posts);
            setPagination({
                page: response.data.pagination.page,
                size: response.data.pagination.size,
                totalElements: response.data.pagination.totalElements,
                totalPages: response.data.pagination.totalPages,
                last: response.data.pagination.last,
            });

            const response_building = (await axiosInstance.get(`/api/community/building?uuid=${id}`)).data;
            setCommunityName(response_building.community.name);
        } catch (error) {
            console.error('Failed to fetch posts', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (newPage: number) => {
        setPagination((prev) => ({ ...prev, page: newPage }));
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        debouncedSetSearchTerm(event.target.value);
    };

    const debouncedSetSearchTerm = useCallback(
        debounce((value: string) => {
            setDebouncedSearchTerm(value);
            console.log('Debounced search term:', value);
        }, 500),
        []
    );

    useEffect(() => {
        setPagination((prev) => ({ ...prev, page: 0 }));
        fetchPosts();
    }, [debouncedSearchTerm]);

    const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setPagination((prev) => ({ ...prev, page: 0 }));
        fetchPosts();
    };

    const handleWritePost = () => {
        if (!isAuthenticated) {
            Swal.fire({
                title: '로그인이 필요합니다.',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: '로그인',
                cancelButtonText: '취소',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/auth/signin', {
                        state: { from: `/community/building/${id}` },
                    });
                }
            });
        } else {
            navigate(`/community/building/${id}/write`);
        }
    };

    return (
        <div className="board-page">
            <div>
                <Link to={`/community/building/${id}`} className="back-link">
                    <FontAwesomeIcon icon={faChevronLeft} /> {communityName}
                </Link>
            </div>
            <div className="board-header">
                <h1>커뮤니티 게시판</h1>
                <button onClick={handleWritePost} className="write-button">
                    새 글 작성
                </button>
            </div>
            <form onSubmit={handleSearchSubmit} className="search-form">
                <input
                    type="text"
                    placeholder="게시글 검색"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="search-input"
                />
                <button type="submit" className="search-button">검색</button>
            </form>
            {loading ? (
                <div className="loading">
                    <div className="loader"></div>
                    <p>게시글을 불러오는 중...</p>
                </div>
            ) : posts.length > 0 ? (
                <div className="post-grid">
                    {posts.map((post) => (
                        <Link key={post.id} to={`/community/building/${id}/post/${post.id}`} className="post-card">
                            <h3 className="post-title">{post.title}</h3>
                            <div className="post-meta">
                                <div className="post-meta-items">
                                    <div>
                                        <FontAwesomeIcon icon={faEye} />
                                        <span> {post.viewCount}</span>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faThumbsUp} />
                                        <span> {post.likeCount}</span>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faComments} />
                                        <span> {post.replyCount}</span>
                                    </div>
                                </div>
                                <span>{formatCreatedAt(post.createdAt)}</span>
                            </div>
                        </Link>
                    ))}
                </div>
            ) : (
                <div className="no-posts">게시글이 없습니다.</div>
            )}
            <div className="pagination">
                <button
                    onClick={() => handlePageChange(pagination.page - 1)}
                    disabled={pagination.page === 0 || pagination.totalPages === 0}
                    className="pagination-button"
                >
                    이전
                </button>
                {pagination.totalPages > 0 ? (
                    Array.from({ length: pagination.totalPages }, (_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index)}
                            className={`pagination-button ${pagination.page === index ? 'active' : ''}`}
                        >
                            {index + 1}
                        </button>
                    ))
                ) : (
                    <button className="pagination-button active">1</button>
                )}
                <button
                    onClick={() => handlePageChange(pagination.page + 1)}
                    disabled={pagination.last || pagination.totalPages === 0}
                    className="pagination-button"
                >
                    다음
                </button>
            </div>
        </div>
    );
};

export default BuildingPostPage;