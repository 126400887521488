import { createContext, useEffect, useState } from 'react';
import axiosInstance from 'utils/axios-instance';

export const AuthContext = createContext<any>(null);

type UserInfo = {
    id: number;
    name: string;
    nickname: string;
}

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [userInfo, setUserInfo] = useState<UserInfo>();
    useEffect(() => {
        checkAuth();
    }, []);

    // useEffect(() => {
    //     if (isAuthenticated) {
    //         setCookie('isAuthenticated', 'true');
    //     } else {
    //         setCookie('isAuthenticated', 'false');
    //     }
    // } , [isAuthenticated]);

    const getIsAuthenticated = () => {
        return isAuthenticated;
    };

    const checkAuth = async () => {
        setIsLoaded(false);
        try {
            const response = await axiosInstance.get('/api/auth/check');
            if (response.status === 200) {
                const data = response.data;
                let user: UserInfo = {
                    id: data.id,
                    name: data.userName,
                    nickname: data.nickname
                }
                setUserInfo(user);
                setIsAuthenticated(true);
            }
        } catch (error) {
            setIsAuthenticated(false);
        }
        setIsLoaded(true);
    };

    const signOut = async () => {
        setIsAuthenticated(false);
        try {
            await axiosInstance.get('/api/auth/signout');
        } catch (error) {
            console.error(error);
            document.location.href = '/';
        }
        setUserInfo(undefined);
    }

    const value = {
        isAuthenticated,
        isLoaded,
        userInfo,
        checkAuth,
        getIsAuthenticated,
        signOut
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}