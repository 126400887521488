import React, { useEffect, useRef } from "react";
import './default_sidebar.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

interface DefaultSideBarProps {
    width?: number;
    children?: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
}

const DefaultSideBar: React.FC<DefaultSideBarProps> = ({
    width = 280,
    children,
    isOpen,
    onClose,
    onOpen
}) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        isOpen ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll');

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [isOpen]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (isOpen && containerRef.current && !containerRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    return (
        <>
            <div className="sidebar-container">
                <div
                    ref={containerRef}
                    className={`sidebar ${isOpen ? 'open' : ''}`}
                    style={{ width: `${width}px` }}
                >
                    <button
                        onClick={(e) => {
                            onClose();
                        }}
                        className="sidebar-button"
                    >
                        <FontAwesomeIcon
                            icon={faXmark as IconProp}
                            size="2x"
                            color="rgb(100, 130, 173)"
                        />
                    </button>
                    <div className="sidebar-content">{children}</div>
                </div>
            </div>
            <div className={`sidebar-overlay ${isOpen ? 'open' : ''}`} />
        </>
    );
}

export default DefaultSideBar;
