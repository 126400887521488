import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import './default_dropdown.css';

interface DefaultDropdownProps {
    displayName: string;
    view: boolean;
    setView: React.Dispatch<React.SetStateAction<boolean>>;
    items: {
        name: string;
        favicon?: IconProp;
        faviconPosition?: 'left' | 'right';
        color?: string;
        onClick: () => void;
    }[];
}

const DefaultDropdown: React.FC<DefaultDropdownProps> = ({
    displayName,
    view,
    setView,
    items,
}) => {

    return (
        <>
            <div className="dropdown-container">
                <ul onClick={() => { setView(!view) }}>
                    {displayName}{" "}
                    {view ? <FontAwesomeIcon icon={faChevronUp as IconProp} /> : <FontAwesomeIcon icon={faChevronDown as IconProp} />}
                    {view && <div className="dropdown-menu">
                        {items.map((item, index) => (
                            <li key={index} onClick={item.onClick}>{
                                item.favicon && item.faviconPosition === 'left' ? <FontAwesomeIcon icon={item.favicon} style={{
                                    marginRight: '5px', color
                                        : item.color ? item.color : 'black'
                                }} /> : null
                            }{item.color ? <span style={{ color: item.color }}>{' '}{item.name}{' '}</span> : " " + item.name + " "}{
                                    item.favicon && item.faviconPosition === 'right' ? <FontAwesomeIcon icon={item.favicon} style={{ marginLeft: '5px', color: item.color ? item.color : 'black' }} /> : null
                                }</li>
                        ))}
                    </div>}
                </ul>
            </div>
        </>
    );
}

export default DefaultDropdown;