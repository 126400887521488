import { AuthContext } from "context/auth/auth_context";
import { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import './signin.css';
import SocialLoginButton from "../components/social_login_button";
import Swal from "sweetalert2";

const SigninPage: React.FC = () => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { from, fromfrom } = location.state || {};
    const prevPath = from || '/';

    const authContext = useContext(AuthContext);

    useEffect(() => {
        if (!fromfrom) return; // fromfrom이 없으면 기본 뒤로가기 동작 유지

        const handlePopState = (e: PopStateEvent) => {
            e.preventDefault();
            navigate(fromfrom, { replace: true });
        };

        window.addEventListener("popstate", handlePopState);

        // 컴포넌트 마운트 직후 히스토리 항목 추가
        window.history.pushState(null, '', window.location.pathname);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, [fromfrom, navigate]);

    useEffect(() => {
        if (!authContext.isLoaded) {
            return;
        }
        const isAuthenticated = authContext.getIsAuthenticated();
        if (isAuthenticated) {
            navigate(prevPath);
        }
    }, [authContext, navigate, prevPath]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const errorParam = params.get('error');
        if (errorParam) {
            // setErrorMessage(errorParam);
            Swal.fire({
                icon: 'error',
                title: '이런...!',
                text: errorParam,
            });
            navigate(location.pathname);
        }
    }, [location, navigate]);

    useEffect(() => {
        if (errorMessage) {
            setModalOpen(true);
        }
    }, [errorMessage]);

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <div className='signin-container'>
            <div className="rounded-lg border bg-card text-card-foreground shadow-sm mx-auto max-w-[400px] space-y-6" style={{ minWidth: '320px' }}>
                <div className="flex flex-col p-6 space-y-2 text-center">
                    <h3 className="whitespace-nowrap tracking-tight text-3xl font-bold">Sign in</h3>
                    {/* <p className="text-sm text-gray-500 dark:text-gray-400" style={{ marginTop: '10px' }}>
                        모든 정보는 암호화되어 안전하게 보호됩니다.
                    </p> */}
                </div>
                <div className="p-6 space-y-4">
                    <div className="space-y-4">
                        <div className="grid gap-2">
                            <label
                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                Email
                            </label>
                            <input
                                placeholder="example@email.com"
                                type="text"
                                name="email"
                                onChange={() => { }}
                                value={''}
                                className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                            />
                        </div>
                        <div className="grid gap-2">
                            <label
                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                Password
                            </label>
                            <input
                                className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                                type="password"
                                onChange={() => { }}
                                value={''}
                                name="password"
                            />
                        </div>
                        <div className="flex justify-between">
                            <button className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 h-10 px-4 py-2 w-full mr-2 bg-[#4285f4] hover:bg-[#5a95f5] text-white">
                                Sign in
                            </button>
                            <button className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border bg-background h-10 px-4 py-2 w-full ml-2 border-[#4285f4] text-[#4285f4] hover:bg-[#4285f4] hover:text-white"
                                onClick={() => navigate('/auth/signup')}
                            >
                                Sign up
                            </button>
                        </div>
                        <div className="flex items-center gap-4">
                            <div className="h-px flex-1 bg-gray-200 dark:bg-gray-700"></div>
                            <span className="text-gray-500 dark:text-gray-400">or</span>
                            <div className="h-px flex-1 bg-gray-200 dark:bg-gray-700"></div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <SocialLoginButton
                                platform="google"
                                redirect_uri={prevPath}
                            />
                            <SocialLoginButton
                                platform="kakao"
                                redirect_uri={prevPath}
                            />
                            <SocialLoginButton
                                platform="naver"
                                redirect_uri={prevPath}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* <ErrorModal open={modalOpen} onClose={handleCloseModal} message={errorMessage || ''} /> */}
        </div>
    );
};

export default SigninPage;