import React, { useContext, useEffect, useState } from "react";
import "./default_footer.css";
import { Link } from "react-router-dom";
import { AuthContext } from "context/auth/auth_context";

export const DefaultFooter: React.FC = () => {
    const authContext = useContext(AuthContext);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState<boolean>(false);

    const checkIsMobile = () => {
        setIsMobile(window.innerWidth <= 767);
    }

    useEffect(() => {
        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);
        return () => {
            window.removeEventListener('resize', checkIsMobile);
        }
    }, []);

    useEffect(() => {
        if (!authContext.isLoaded) {
            return;
        }
        setIsAuthenticated(authContext.getIsAuthenticated());
    }, [authContext]);

    return (
        <div className="footer-container">
            <Link className="logo" to="/" style={{ cursor: 'pointer' }}>
                <img className="logo-image"
                    src="/assets/logo_v2.png"
                    alt="logo"
                    style={{ width: '100px' }}
                />
            </Link>
            <div className="footer-content">
                <div className="footer-auth">
                    {isAuthenticated ? (
                        <>
                            <a href="/user/mypage">마이페이지</a>
                            <span>|</span>
                            <a href="/auth/signout">로그아웃</a>
                        </>
                    ) : (
                        <>
                            <a href="/auth/signin">로그인</a>
                            <span>|</span>
                            <a href="/auth/signup">회원가입</a>
                        </>
                    )}
                    <span>|</span>
                    <a href="/">사이트맵</a>
                </div>
                <div className="footer-links">
                    {isMobile ? null : <span>|</span>}
                    <a href="/terms/user-agreement">이용약관</a>
                    <span>|</span>
                    <a href="/terms/privacy-policy">개인정보처리방침</a>
                </div>
            </div>
            <p style={{
                fontSize: '12px',
            }}>문의 : help@duriburn.com</p>
            <div className="footer-copyright">
                <p>Copyright © {new Date().getFullYear()} duriburn.com All Rights Reserved.</p>
                <p>E-mail : contact@duriburn.com</p>
            </div>
        </div>
    );
};
