import SlideBanner from "components/banner/slide-banner";
import { useLocation } from "react-router-dom";
import SearchFrame from "./components/search-frame";
import BoxFrame from "./components/box-frame";
import './index.css';

const HomePage: React.FC = () => {
    const location = useLocation();
    return (
        <>
            <div className="cbox-container">
                <div className="cbox-left-container">
                    <div className="text-container">
                        <div className="cbox-h1">
                            <p>아직도 동네 리뷰를 안보셨다구요?</p>
                        </div>
                        <div className="cbox-span">
                            <p>내가 사는 동네, 이사가고 싶은 아파트, 살고 있는 빌라의 소식과 리뷰가 궁금하지 않으세요?</p>
                            <p>전국 모든 동네, 아파트, 빌라 등 다양한 장소의 리뷰를 두리번 하세요.</p>
                        </div>
                    </div>
                    <div className="cbox-btn">
                        <p>지금 바로 시작해보세요!</p>
                        <SearchFrame />
                    </div>
                </div>
                <div className="img-container">
                    {/* svg : filename : public/assets/location_review */}
                    <img
                        src="/assets/location_review.svg"
                        alt="location_review"
                        style={{
                            width: "600px",
                        }}
                    />
                </div>
            </div>
            <div style={{
                margin: "30px 10px 30px 10px",
            }}>
                <SlideBanner />
            </div>
            {/* <div
                style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginBottom: "10px",
                }}
            >
                <span>어떤 집을 찾으시나요?</span>
            </div>
            <SearchFrame />
            <div className="box-container">
                <BoxFrame
                    h2Text="동네 살펴보기"
                    pText="우리 지역을 찾아보세요!"
                    imgSrc="https://placehold.co/200x200"
                />
                <BoxFrame
                    h2Text="내 커뮤니티 바로가기"
                    pText="우리 동네 이야기를 나눠요!"
                    imgSrc="https://placehold.co/200x200"
                />
            </div> */}
        </>
    );
};
export default HomePage;