import ErrorModal from "components/error_modal";
import { AuthContext } from "context/auth/auth_context";
import { useState, useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import './signup.css';
import Swal from "sweetalert2";

const SignupPage: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();

    const authContext = useContext(AuthContext);

    useEffect(() => {
        if (!authContext.isLoaded) {
            return;
        }
        const isAuthenticated = authContext.getIsAuthenticated();
        if (isAuthenticated) {
            navigate('/');
        }
    }, [authContext, navigate]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const errorParam = params.get('error');
        if (errorParam) {
            // setErrorMessage(errorParam);
            Swal.fire({
                icon: 'error',
                title: '이런...!',
                text: errorParam,
            });
            navigate(location.pathname);
        }
    }, [location, navigate]);

    useEffect(() => {
        if (errorMessage) {
            setModalOpen(true);
        }
    }, [errorMessage]);

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <div className="signup-container">
            <div className="rounded-lg border bg-card text-card-foreground shadow-sm mx-auto max-w-[400px] space-y-6" style={{ minWidth: '320px' }}>
                <div className="flex flex-col p-6 space-y-2 text-center">
                    <h3 className="whitespace-nowrap tracking-tight text-3xl font-bold">Sign up</h3>
                    <p className="text-sm text-gray-500 dark:text-gray-400" style={{ marginTop: '10px' }}>
                        모든 정보는 암호화되어 안전하게 보호됩니다.
                    </p>
                </div>
                <div className="p-6 space-y-4">
                    <div className="space-y-4">
                        <div className="grid gap-2">
                            <label
                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                Email
                            </label>
                            <input
                                placeholder="example@email.com"
                                type="text"
                                name="email"
                                onChange={(value) => {
                                    setEmail(value.target.value);
                                }}
                                value={email}
                                className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                            />
                        </div>
                        <div className="grid gap-2">
                            <label
                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                Password
                            </label>
                            <input
                                className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                                type="password"
                                onChange={(value) => {
                                    setPassword(value.target.value);
                                }}
                                value={password}
                                name="password"
                            />
                        </div>
                        <div className="grid gap-2">
                            <label
                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                Confirm Password
                            </label>
                            <input
                                className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                                type="password"
                                onChange={(value) => {
                                    setConfirmPassword(value.target.value);
                                }}
                                value={confirmPassword}
                                name="confirm password"
                            />
                        </div>
                        <div className="grid gap-2">
                            <label
                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                Name
                            </label>
                            <input
                                placeholder="홍길동"
                                type="text"
                                name="name"
                                onChange={(value) => {
                                    setName(value.target.value);
                                }}
                                value={name}
                                className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                            />
                        </div>
                    </div>
                </div>
                <div className="flex justify-between ml-6 mr-6">
                    <button className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 h-10 px-4 py-2 w-full bg-[#4285f4] hover:bg-[#5a95f5] text-white">
                        회원가입 진행
                    </button>
                </div>
                <div className="flex items-center gap-4">
                    <div className="h-px flex-1 bg-gray-200 dark:bg-gray-700"></div>
                    <span className="text-gray-500 dark:text-gray-400">or</span>
                    <div className="h-px flex-1 bg-gray-200 dark:bg-gray-700"></div>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '10px',
                    marginTop: '0px'
                }}>
                    <Link to="/auth/signin" className="already-signup-text">이미 계정이 있으신가요?</Link>
                </div>
            </div>
            <ErrorModal open={modalOpen} onClose={handleCloseModal} message={errorMessage || ''} />
        </div>
    );
}

export default SignupPage;