import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axiosInstance from 'utils/axios-instance';
import DOMPurify from 'dompurify';
import './detail.css';
import Swal from 'sweetalert2';
import { AuthContext } from 'context/auth/auth_context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChevronLeft, faChevronRight, faComments, faEye, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { formatCreatedAt } from 'utils/dateformatter';

const purifyConfig = {
    ADD_TAGS: ['iframe', 'video'],
    ADD_ATTR: ['allowfullscreen', 'frameborder', 'src', 'height', 'width', 'controls', 'preload', 'style'],
    ALLOWED_URI_REGEXP: /^https:\/\/(www\.youtube\.com|i\.duriburn\.com)/i,
    ALLOW_DATA_ATTR: true,
    ALLOW_UNKNOWN_PROTOCOLS: true,
    ALLOW_STYLE_PROPS: ['width', 'height', 'max-width', 'max-height', 'min-width', 'min-height', 'aspect-ratio', 'display', 'position', 'top', 'left', 'right', 'bottom'],
};

type PostSimple = {
    id: number;
    postType: string | null;
    communityUuid: string;
    title: string;
    content: string;
    createdBy: number;
    createdAt: string;
    updatedAt: string | null;
    deletedAt: string | null;
    isDeleted: boolean;
    likeCount: number;
    viewCount: number;
    replyCount: number;
};

type PostDetail = {
    id: number;
    postType: string | null;
    communityUuid: string;
    title: string;
    content: string;
    createdBy: number;
    createdAt: string;
    updatedAt: string | null;
    deletedAt: string | null;
    isDeleted: boolean;
    likeCount: number;
    viewCount: number;
    replyCount: number;
    replies: reply[];
    isMine: boolean;
    isLiked: boolean;
};

type reply = {
    id: number;
    content: string;
    isReply: boolean;
    replyId: number | null;
    postId: number;
    createdBy: number;
    createdAt: string;
    replies: reply[];
}

type ApiResponse = {
    statusCode: number;
    communityPost: PostDetail;
    nearPosts: PostSimple[];
    nextPost: PostSimple | null;
    previousPost: PostSimple | null;
};

const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString('ko-KR', options);
};

const Comment: React.FC<{ comment: reply; onReply: (parentId: number, content: string) => void, isAuthenticated: Boolean }> = ({ comment, onReply, isAuthenticated }) => {
    const [showReplyForm, setShowReplyForm] = useState(false);
    const [replyContent, setReplyContent] = useState('');

    const handleReplySubmit = () => {
        if (replyContent.trim() === "") {
            alert('댓글 내용이 비어 있습니다.');
            return;
        }
        onReply(comment.id, replyContent);
        setReplyContent('');
        setShowReplyForm(false);
    };

    return (
        <div className={`comment ${comment.isReply ? 'reply' : ''}`}>
            <p className="comment-content">{comment.content}</p>
            <div className="comment-info">
                <span className="comment-date">{formatDate(comment.createdAt)}</span>
            </div>
            {comment.replies && comment.replies.length > 0 && (
                <div className="comment-replies">
                    {comment.replies.map(reply => (
                        <Comment key={reply.id} comment={reply} onReply={onReply} isAuthenticated={isAuthenticated} />
                    ))}
                </div>
            )}
            <div className="comment-reply-info">
                {comment.isReply !== true && <button className="" onClick={() => setShowReplyForm(!showReplyForm)}>답글 작성</button>}
            </div>
            {showReplyForm && (
                <div className={`reply-form ${isAuthenticated ? '' : 'disabled'}`}>
                    <textarea
                        value={replyContent}
                        onChange={(e) => setReplyContent(e.target.value)}
                        placeholder={isAuthenticated ? '답글을 입력하세요...' : '로그인 후 답글을 작성할 수 있습니다.'}
                    />
                    <button onClick={handleReplySubmit}>답글 작성</button>
                </div>
            )}
        </div>
    );
};

const BuildingCommunityPostDetailPage: React.FC = () => {
    const { id, postId } = useParams<{ id: string; postId: string }>();
    const navigate = useNavigate();
    const [post, setPost] = useState<PostDetail | null>(null);
    const [loading, setLoading] = useState(true);
    const [newComment, setNewComment] = useState('');
    const authContext = useContext(AuthContext);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [nearPosts, setNearPosts] = useState<PostSimple[]>([]);
    const [nextPost, setNextPost] = useState<PostSimple | null>(null);
    const [previousPost, setPreviousPost] = useState<PostSimple | null>(null);

    useEffect(() => {
        if (!authContext.isLoaded) {
            return;
        }
        setIsAuthenticated(authContext.getIsAuthenticated());
    }
        , [authContext]);

    const handleLike = async () => {
        if (!isAuthenticated) {
            Swal.fire({
                title: '로그인이 필요합니다.',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: '로그인',
                cancelButtonText: '취소',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/auth/signin', {
                        state: { from: `/community/building/${id}/post/${postId}` },
                    });
                }
            });
            return;
        }

        try {
            const response = await axiosInstance.post(`/api/community/building/post/like`, {
                postId: postId
            });
            if (response.status === 201) {
                if (response.data.liked) {
                    Swal.fire({
                        icon: 'success',
                        title: '좋아요 완료',
                        text: '게시글 좋아요가 완료되었습니다.',
                    }).then(() => {
                        fetchPost();
                    });
                } else {
                    Swal.fire({
                        icon: 'success',
                        title: '좋아요 취소',
                        text: '게시글 좋아요가 취소되었습니다.',
                    }).then(() => {
                        fetchPost();
                    });
                }
            }
        } catch (error) {
            console.error('Failed to like post', error);
            Swal.fire({
                icon: 'error',
                title: '좋아요 실패',
                text: '게시글 좋아요에 실패했습니다.',
            });
        }
    };


    const handleCommentSubmit = async () => {
        try {
            const response = await axiosInstance.post(`/api/community/building/post/comment`, {
                postId: postId,
                content: newComment,
                isReply: false,
                replyId: null
            });
            if (response.status === 201) {
                setNewComment('');
                Swal.fire({
                    icon: 'success',
                    title: '댓글 작성 완료',
                    text: '댓글이 성공적으로 작성되었습니다.',
                }).then(() => {
                    fetchPost();
                });
            }
        } catch (error) {
            console.error('Failed to submit comment', error);
            Swal.fire({
                icon: 'error',
                title: '댓글 작성 실패',
                text: '댓글을 작성하는데 실패했습니다.',
            });
        }
    };

    const handleReply = async (parentId: number, content: string) => {
        try {
            const response = await axiosInstance.post(`/api/community/building/post/comment`, {
                postId: postId,
                content: content,
                isReply: true,
                replyId: parentId
            });
            if (response.status === 201) {
                Swal.fire({
                    icon: 'success',
                    title: '답글 작성 완료',
                    text: '답글이 성공적으로 작성되었습니다.',
                }).then(() => {
                    fetchPost();
                });
            }
        } catch (error) {
            console.error('Failed to submit reply', error);
            Swal.fire({
                icon: 'error',
                title: '답글 작성 실패',
                text: '답글을 작성하는데 실패했습니다.',
            });
        }
    };

    const fetchPost = async () => {
        try {
            const response = await axiosInstance.get<ApiResponse>(`/api/community/building/post/detail?id=${postId}`);
            setPost(response.data.communityPost);
            setNearPosts(response.data.nearPosts);
            setNextPost(response.data.nextPost);
            setPreviousPost(response.data.previousPost);
        } catch (error) {
            console.error('Failed to fetch post', error);
            Swal.fire({
                icon: 'error',
                title: '게시글 로딩 실패',
                text: '게시글을 불러오는데 실패했습니다.',
            }).then(() => {
                navigate(`/community/building/${id}/post`);
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axiosInstance.get<ApiResponse>(`/api/community/building/post/detail?id=${postId}`);
                setPost(response.data.communityPost);
                setNearPosts(response.data.nearPosts);
                setNextPost(response.data.nextPost);
                setPreviousPost(response.data.previousPost);
            } catch (error) {
                console.error('Failed to fetch post', error);
                Swal.fire({
                    icon: 'error',
                    title: '게시글 로딩 실패',
                    text: '게시글을 불러오는데 실패했습니다.',
                }).then(() => {
                    navigate(`/community/building/${id}/post`);
                });
            } finally {
                setLoading(false);
            }
        };

        fetchPost();
    }, [postId]);

    if (loading) {
        return <div className="loading">
            <div className="loader"></div>
            <p>게시글을 불러오는 중...</p>
        </div>;
    }

    if (!post) {
        return <div className="post-detail=error-message">게시글을 찾을 수 없습니다.</div>;
    }

    const organizeComments = (comments: reply[]): reply[] => {
        const commentMap = new Map<number, reply>();
        const topLevelComments: reply[] = [];

        comments.forEach(comment => {
            comment.replies = [];
            commentMap.set(comment.id, comment);
            if (!comment.isReply) {
                topLevelComments.push(comment);
            }
        });

        comments.forEach(comment => {
            if (comment.isReply && comment.replyId) {
                const parentComment = commentMap.get(comment.replyId);
                if (parentComment) {
                    parentComment.replies.push(comment);
                }
            }
        });

        return topLevelComments;
    };

    const deletePost = async () => {
        Swal.fire({
            title: '게시글을 삭제하시겠습니까?',
            text: '삭제된 게시글은 복구할 수 없습니다.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: '삭제',
            cancelButtonText: '취소',
        }).then((result) => {
            if (result.isConfirmed) {
                deletePostConfirmed();
            }
        });
    }

    const deletePostConfirmed = async () => {
        if (postId === null) {
            Swal.fire({
                icon: 'error',
                title: '잠시 후 다시 시도해주세요',
            });
            return;
        }
        try {
            const response = await axiosInstance.post(`/api/community/building/post/delete`, {
                postId: postId as unknown as number
            });
            if (response.status === 201) {
                Swal.fire({
                    icon: 'success',
                    title: '게시글 삭제 완료',
                    text: '게시글이 성공적으로 삭제되었습니다.',
                }).then(() => {
                    navigate(`/community/building/${id}/post`);
                });
            }
        } catch (error) {
            console.error('Failed to delete post', error);
            Swal.fire({
                icon: 'error',
                title: '게시글 삭제 실패',
                text: '게시글 삭제에 실패했습니다.',
            });
        }
    }

    return (
        <div className="post-detail-container">
            <div className="post-detail-header">
                <h1 className="post-detail-title">{post.title}</h1>
                <div className="post-detail-info">
                    <span className="post-detail-date">{formatDate(post.createdAt)}</span>
                    <div className="post-detail-items">
                        <div>
                            <FontAwesomeIcon icon={faEye} />
                            <span> {post.viewCount}</span>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faThumbsUp} />
                            <span> {post.likeCount}</span>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faComments} />
                            <span> {post.replyCount}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="post-detail-content"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content, purifyConfig) }}
            />
            <div className="post-detail-actions">
                <button className={`post-detail-like-button ${post.isLiked ? 'liked' : ''}`} onClick={handleLike}>
                    <FontAwesomeIcon
                        icon={faThumbsUp}
                    /> {post.likeCount}
                </button>
                {
                    post.isMine && (
                        <>
                            <button
                                className="post-detail-edit-button"
                                onClick={() => navigate(`/community/building/${id}/post/${postId}/edit`)}
                            >
                                수정
                            </button>
                            <button
                                className="post-detail-delete-button"
                                onClick={deletePost}
                            >
                                삭제
                            </button>
                        </>
                    )
                }
                <button className="post-detail-back-button" onClick={() => navigate(`/community/building/${id}/post`)}>
                    <FontAwesomeIcon
                        icon={faBars}
                    /> 목록
                </button>
            </div>
            <div className="post-detail-comments">
                <h2 className="comments-title">댓글 ({post.replyCount})</h2>
                {post.replies.length > 0 ? (
                    organizeComments(post.replies).map((comment) => (
                        <Comment key={comment.id} comment={comment} onReply={handleReply} isAuthenticated={isAuthenticated} />
                    ))
                ) : (
                    <p className="no-comments">아직 댓글이 없습니다.</p>
                )}
                <div className={`new-comment-form ${isAuthenticated ? '' : 'disabled'}`}>
                    <textarea
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        placeholder={isAuthenticated ? '댓글을 입력하세요...' : '로그인 후 댓글을 작성할 수 있습니다.'}
                    />
                    <button
                        onClick={handleCommentSubmit}
                        disabled={!isAuthenticated}
                    >댓글 작성</button>
                </div>
            </div>
            <div className="post-navigation">
                {previousPost ? (
                    <Link to={`/community/building/${id}/post/${previousPost.id}`} className="nav-link prev">
                        <FontAwesomeIcon icon={faChevronLeft} /> 이전글: {previousPost.title}
                    </Link>
                ) :
                    <div className="nav-link prev disabled">
                        <FontAwesomeIcon icon={faChevronLeft} /> 이전 글이 없습니다.
                    </div>
                }
                {nextPost ? (
                    <Link to={`/community/building/${id}/post/${nextPost.id}`} className="nav-link next">
                        다음글: {nextPost.title} <FontAwesomeIcon icon={faChevronRight} />
                    </Link>
                ) :
                    <div className="nav-link next disabled">
                        다음 글이 없습니다. <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                }
            </div>

            <div className="nearby-posts">
                <h3 className="nearby-posts-title">
                    <Link
                        style={{
                            display: 'inline',
                            justifyContent: 'initial',
                            alignItems: 'initial',
                            padding: '0',
                            textDecoration: 'none',
                            color: 'inherit'
                        }}
                        to={`/community/building/${id}/post`}>글 목록</Link>
                </h3>
                <ul className="nearby-posts-list">
                    {nearPosts.map((nearPost) => (
                        <li key={nearPost.id} className={nearPost.id === post.id ? 'nearby-post-item current-post' : 'nearby-post-item'}>
                            <Link to={`/community/building/${id}/post/${nearPost.id}`} className="nearby-post-link">
                                <span className="nearby-post-title">{nearPost.title}</span>
                                <span className="nearby-post-date">{formatCreatedAt(nearPost.createdAt)}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="post-detail-actions">
                <button className={`post-detail-like-button ${post.isLiked ? 'liked' : ''}`} onClick={handleLike}>
                    <FontAwesomeIcon
                        icon={faThumbsUp}
                    /> {post.likeCount}
                </button>
                {
                    post.isMine && (
                        <>
                            <button
                                className="post-detail-edit-button"
                                onClick={() => navigate(`/community/building/${id}/post/${postId}/edit`)}
                            >
                                수정
                            </button>
                            <button
                                className="post-detail-delete-button"
                                onClick={deletePost}
                            >
                                삭제
                            </button>
                        </>
                    )
                }
                <button className="post-detail-back-button" onClick={() => navigate(`/community/building/${id}/post`)}>
                    <FontAwesomeIcon
                        icon={faBars}
                    /> 목록
                </button>
            </div>
        </div>
    );
};

export default BuildingCommunityPostDetailPage;